table {
	width:100%
  }

  table th {
	text-align: left;
	padding: 30px 0 30px 10px;
	color:#252a3b;
	font-weight: 500;
	font-family: 'Euclid Circular A';
	font-size: 14px;
  }

  table td {
    padding: 15px 0 15px 10px;
    text-align: left;
    color: #4e536e;
    font-size: 14px;
    font-weight: normal;
    vertical-align: top;
  }

  table td:first-child, table th:first-child{
	text-align: center;
  }
  table td span.table-download-icon, table td span.table-delete-icon{
	cursor: pointer;
  width: 24px;
  }
  .table-action-btns{
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-start;
  }
  table tr:last-child {
    border-bottom: 1px dashed #d6d8e3;
  }


  .dashboard-view-bol-hover:hover {
    background-color:rgb(232, 244, 247)!important; 
  }
  table tbody tr {
    border-top: 1px dashed #d6d8e3;
  }
  .table-action-btns img {
    max-width: 20px;
    width: 100%;
    height: 20px;
    object-fit: cover;
  }
  .content-table-wrapper table th:last-child{
    text-align: left;
  }
  .content-table-wrapper {
    padding-left: 10px;
  }
  .header-sno {
    width: 5%;
  }
  .header-cname {
    width: 30%;
  }
  .header-address {
    width: 25%;
  }
  .header-country {
    width: 20%;
  }
  .msg-disable img {
    opacity: 0.3;
  }
  .ellipsis {
    max-width: 110px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}