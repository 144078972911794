body {
    -webkit-print-color-adjust: exact;
    -moz-print-color-adjust: exact;
    -ms-print-color-adjust: exact;
    print-color-adjust: exact;
}
a{text-decoration: none;}
table{
    box-shadow: none!important;
    background: none!important;
    width: 100%!important;
    border-collapse: collapse!important;
}
table.bordered-table, table.bordered-table th, table.bordered-table td,table.bordered-table-main th, table.bordered-table-main td {
    border: 1px solid black;
    border-collapse: collapse;
}
.table td{
    
}
.textALign-left{
    text-align: left;
}
td{
    text-align: left!important;
}
.boxsection{
    width: 92%;
    max-width: 1180px;
    padding: 1rem;
    margin: 40px auto;
    border-radius: 15px;
    background-color: #fff;
    box-shadow: 0px 12px 32px 0px rgba(72, 75, 97, 0.05);
    cursor: pointer;
}

.main-table p.td-headings{
    font-family: 'Euclid Circular A';
    font-weight: 500;
    font-size: 14px;
    /* color: #000; */
    margin: 0;
    padding-bottom: 6px;
}


.main-table p.td-headings small{
    font-size: 14px;
}

.main-table p.td-headings:not(:first-child){
    padding-top: 20px;
}

.td-padding{
    padding: 5px 10px;
}

.td-padding-sub{
    padding: 10px 10px;
}

.main-table p{
    font-family: 'RedditMono';
    font-weight: 300;
    font-size: 12px;
    margin: 0;
}

.main-table div{
    font-family: 'RedditMono';
    font-weight: 300;
    font-size: 13px;
    margin: 0;
    word-break: break-word;
}

.sub-table td div{
    font-family: 'RedditMono';
    font-size: 16px;
}

.sub-table th{
    font-size: 15px;
    font-family: 'Euclid Circular A';
    font-weight: 500;
    text-align: left;
    text-transform: uppercase;
}

.main-table a{
    text-decoration: none;
    color: #000;
}

*{
    box-sizing: border-box;
}

sup.mandatory{
    color: #ff6000
}

.valign-top{
    vertical-align: top;
}

.table-bottom-text{
    text-align: right;
    font-size: 14px;
    font-family: 'Euclid Circular A';
    font-weight: 500;
    padding: 15px 0 15px;
}

.bl-none{
    border-left: none !important;
}

.br-none{
    border-right: none !important;
}

.bb-none{
    border-bottom: none !important;
}

.bt-none{
    border-top: none !important;
}

.perticular-table {
    min-height: 16vw;
}

.default-height {
    min-height: 3vw;
}

@media screen and (max-width:1550px) {
    .sub-table td div{
        font-size: 15px;
    }
}

@media screen and (max-width:1450px) {
    .sub-table td div{
        font-size: 13px;
    }
}

@media screen and (max-width: 1300px) {
    .sub-table td div{
        font-size: 16px;
    }
}

@media screen and (max-width: 1200px) {
    .sub-table td div{
        font-size: 13px;
    }
}

@media screen and (max-width: 1075px) {
    .sub-table td div{
        font-size: 14px;
    }
}

@media screen and (max-width: 991px) {
    .sub-table td div{
        font-size: 10px;
    }
}