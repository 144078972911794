.member-management .content-header-search {
    max-width: 825px;
    width: 100%;
}
.member-management .content-header-search .search-input {
    flex-grow: 1;
}

@media screen and (max-width: 1400px)  {
    .content-header.member-management {
        flex-direction: column;
        align-items: flex-start;
    }
    .content-header-search {
        margin-top: 20px;
        padding-left: 20px;
    }
}