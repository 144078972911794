.right-container {
    flex-grow: 1;
    position: relative;
    max-width: 1800px;
    width: calc(100% - 342px);
    transition: all 0.2s ease-in-out;
}

.topmenu-wrapper{
	background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
	padding: 15px 90px 15px 75px;
    column-gap: 34px;
	box-shadow: 0px 12px 32px 0px rgba(72, 75, 97, 0.05);
}


.topmenu-wrapper .page-heading h3 {
	font-size: 32px;
	font-weight: 500;
	color:#212428
}

.account-user-dropdown .profile-picture {
    width: 54px;
    height: 54px;
    overflow: hidden;
    border-radius: 50%;
}
.profile-picture > img {
    border-radius: 50%;
    object-fit: cover;
    object-position: top;
    width: 100%;
    height: 100%;
    transition: all 0.6s cubic-bezier(0.25, 1, 0.5, 1);
}
.profile {
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: all 0.6s cubic-bezier(0.33, 1, 0.68, 1);
    column-gap: 20px;
    flex-shrink: 0;
    cursor: pointer;
}
.profile .user-name {
	font-weight: 600;
	font-size: 18px;
	color:#111111;
}
.profile .user-mail {
	display: block;
	color:#111111;
	font-size: 15px;
	font-weight: 300;
}
a.profile-dropdown {
    width: 28px;
    height: 28px;
    background-color: #f0f2f6;
    border-radius: 100%;
    background-image: url(../../../public/images/down-arrow-grey.png);
    transition: all .3s ease-in-out;
}
a.profile-dropdown.menu-icon-active {
    transform: rotate(180deg);
}

.dropdown-list .dropdown-menu.shadow.animated--grow-in {
	font-weight: normal;
	padding: 0;
	border-radius: 10px;
	/* box-shadow: 0 0.15rem 1.75rem 0 rgb(146 147 152 / 15%) !important; */
	box-shadow: 3px 5px 50px rgb(0 0 0 / 10%) !important;
	border: 2px solid rgb(255 246 247);
	width: 262px;
	right: 0;
	background: rgb(255 255 255);
	top: 67px;
	box-sizing: border-box;
}
.dropdown-list .dropdown-menu-right {
    right: 0;
    left: auto;
}
.dropdown-list .dropdown-menu.shadow.animated--grow-in {
    font-weight: normal;
    padding: 0;
    border-radius: 10px;
    /* box-shadow: 0 0.15rem 1.75rem 0 rgb(146 147 152 / 15%) !important; */
    box-shadow: 3px 5px 50px rgb(0 0 0 / 10%) !important;
    border: 2px solid rgb(255 246 247);
    width: 262px;
    right: 0;
    background: rgb(255 255 255);
    top: 67px;
    box-sizing: border-box;
}
.dropdown-list .dropdown-menu {
    position: absolute;
    top: 100%;
    left: auto;
    z-index: 1000;
    min-width: auto;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 0.25rem;
}
.dropdown-list .dropdown-menu ul {
    margin: 3px 4px 5px;
    padding: 7px 10px 0 10px;
    color: #111;
}
.dropdown-list ul {
    overflow-x: auto;
    max-height: 385px;
}
.dropdown-list .dropdown-menu ul li {
    border-bottom: 1px solid rgb(236 231 231 / 37%);
    /* margin-bottom: 10px; */
    padding: 10px 5px 18px;
    cursor: pointer;
}
.dropdown-list .dropdown-menu ul li span {
    color:#000;
}
.dropdown-list .dropdown-menu ul li span:hover {
    color:#0e72b3;
}
.dropdown, .dropleft, .dropright, .dropup {
    position: relative;
}

/* ------MEDIA QUERY--------------------------------------- */

@media screen and (max-width: 1845px) {
    .right-container {
		max-width: 97.561vw;
		width: calc(100% - 18.537vw);
	}
	
	.topmenu-wrapper{
		padding: 1.1vw 4.878vw 1.1vw 4.065vw;
		column-gap: 1.843vw;
	}
    .topmenu-wrapper .page-heading h3 {
		font-size: 32px;
	}
}
@media screen and (max-width: 1300px) {
    .right-container {
		max-width: 100%;
		width: 100%;
	}
    .topmenu-wrapper {
		/* display: none; */
		/* position: absolute;
		right: 0;
		top: -60px; */
		height: auto;
		width: auto;
		padding-top: 0;
		padding-bottom: 0;
		/* padding-right: 6px; */
	}
	.profile {
		padding: 0;
		flex-direction: row;
		align-items: center;
		column-gap: 10px;
	}
	.nav-buttons .profile-picture {
		width: 32px;
		height: 32px;
	}
	.profile h3, .profile p {
		display: none;
	}
    .dropdown-list .dropdown-menu.shadow.animated--grow-in {
		right: 0;
		top: 44px;
	}
}

  /* -----31-08-2023--Start-------- */
  .account-user-dropdown {
    position: relative;
  }
  .notification-bell-icon {
    position: absolute;
    top: 13px;
    left: -17px;
    cursor: pointer;
  }
  .notify-count {
    background: #0e72b3;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    text-align: center;
    top: -9px;
    position: absolute;
    left: 17px;
    color: #fff;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .notification-popup {
    position: absolute;
    background: #f8f8f8;
    padding: 12px;
    box-shadow: 3px 4px 25px 0px rgba(0, 0, 0, 0.20);
    width: 690px;
    top: 87px;
    right: 8%;
    border-radius: 12px;
    z-index: 10000;
  }
  .notification-popup ul {
    margin: 0;
    padding: 0;
    min-height: 80px;
    position: relative;
  }
  .notification-popup li {
    font-size: 16px;
    padding: 10px;
    cursor: pointer;
  }
  .notification-popup li a {
    text-decoration: underline;
    color: #0e72b3;
  }
  .notification-popup::before {
    content: "";
    /* width: 24px; */
    /* height: 26px; */
    display: block;
    /* background: #fff; */
    border-left: 15px solid #0000000d;
    box-shadow: 3px -6px 25px rgba(0, 0, 0, 20%);
    /* transform: rotate(45deg); */
    position: absolute;
    top: -15px;
    right: 42%;
    border-right: 15px solid #0000000d;
    border-bottom: 15px solid #f8f8f8;
  }
  .notification-popup h4 {
    padding: 8px;
    font-weight: bold;
    font-size: 21px;
    font-family: 'Euclid Circular A';
    margin-bottom: 14px;
    border-bottom: 1px solid #ccc;
  }
  .notification-popup li.read {
    background: #deeff8;
    margin-bottom: 5px;
  }
  @media screen and (max-width: 1300px) { 
    .notification-popup {
      top: 80px;

    }
    .notification-popup::before {
      right: 80px;
    }
  }

  @media screen and (max-width: 990px) { 
    .notification-popup {
      width: 590px;
      right: 85px;
    }
    .notification-popup::before {
      right: 69px;
    }
  }

  .notification-popup-close{
    cursor: pointer;
    position: absolute;
    right: 22px;
    top: 22px;
  }
  .notification-popup li:hover{
    background-color: #0e72b3 ;
    color: #fff;
  }
  .notification-count{
    margin-left: 10px;
    border-radius: 30px;
    width: 25px;
    height: 25px;
    background: #0e72b3;
    display: inline-flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    color: #fff;
    font-size: 12px;
  }

  .notification-popup li:hover .notification-count{
    background: #fff;
    color: #0e72b3;
  }

