.contact-list .content-header-search {
    max-width: 507px;
    width: 100%;
}
.contact-list .content-header-search .search-input {
    flex-grow: 1;
}
.contact-list .content-header-search .search-input input[type=text] {
    max-width: 343px;
}
.contact-name-width {
    /* width: 83%; */
  }

.contact-list  .content-header-search {
    max-width: 825px;
    width: 100%;
}
@media screen and (max-width: 1400px)  {
    .content-header.contact-list {
        flex-direction: column;
        align-items: flex-start;
    }
}