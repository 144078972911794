@font-face {
    font-family: 'Euclid Circular A';
    src: url(../public/fonts/EuclidCircularA-Bold.woff) format('woff'),url(../public/fonts/EuclidCircularA-Bold.woff2) format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid Circular A';
    src: url(../public/fonts/EuclidCircularA-Light.woff) format('woff'),url(../public/fonts/EuclidCircularA-Light.woff2) format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid Circular A';
    src: url(../public/fonts/EuclidCircularA-Medium.woff) format('woff'),url(../public/fonts/EuclidCircularA-Medium.woff) format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid Circular A';
    src: url(../public/fonts/EuclidCircularA-Regular.woff) format('woff'), url(../public/fonts/EuclidCircularA-Regular.woff2) format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid Circular A';
    src: url(../public/fonts/EuclidCircularA-SemiBold.woff) format('woff'), url(../public/fonts/EuclidCircularA-SemiBold.woff2) format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Euclid Circular B';
    src: url(../public/fonts/EuclidCircularB-Light.woff) format('woff'), url(../public/fonts/EuclidCircularB-Light.woff2) format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Euclid Circular B';
    src: url(../public/fonts/EuclidCircularB-SemiBold.woff) format('woff'), url(../public/fonts/EuclidCircularB-SemiBold.woff2) format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'RedditMono';
    src: url(../public/fonts/RedditMono-Regular.woff) format('woff'), url(../public/fonts/RedditMono-Regular.woff2) format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

html, body {
	margin: 0px;
	padding: 0px;
	width: 100%;
	min-height: 100%;
}

body {
	font-family: 'Euclid Circular A';
	color: #161824;
    font-weight: normal;
    font-style: normal;
	font-size: 12px;
	background: #FFF ;
}

* {
	box-sizing: border-box;
	transition: all .3s ease-in-out;
  }

a, a:link, a:visited {
    outline: none;
	text-decoration: none;	
	-moz-tap-highlight-color: transparent; 
	-webkit-tap-highlight-color: transparent; 
}

img {
	border: none;
	margin: 0px;
	max-width: 100%;
}


h1 {
	font-family: 'Euclid Circular A';
    font-weight: normal;
	font-size: 28px;
	margin: 0px;
	padding: 0px;	
}

h2 {
	font-family: 'Euclid Circular A';
	font-weight: normal;
	font-size: 24px;
	margin: 0px;
	padding: 0px;
}

h3, h4, h5, h6 {
	font-family: 'Euclid Circular A';
    font-weight: normal;
	font-size: 18px;
	margin: 0px;
	padding: 0px;	
}

form, input, select, textarea, p {
	margin: 0px;
	padding: 0;
}

textarea {
	resize: none;
}

input:focus, textarea:focus {
	outline: none;
}

input::-moz-focus-inner { 
    border: 0;
    padding: 0;
}

article, aside, details, figcaption, figure,  
footer, header, hgroup, menu, nav, section, time {  
	display: block; 
	margin: 0px;
	padding: 0; 
} 

.customClear {
	clear: both;
	font-size: 0em;
	line-height: 0px;
	height: 0px;
	margin: 0px;
	padding: 0px;
}
li {
	list-style: none;
}

/*IE below 9*/
#ieWrapper {
	background: #000;
	position: fixed;
	z-index: 100;
	top: 0;	left: 0;
	width: 100%;
	height: 100%;
	font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
}

.ieHolder {
	position: relative; 
	width: 555px;
	padding: 20px;
	margin: 200px auto 0 auto;
	background: #FFF;
	border: 7px solid #2b2927;
}

.btnIeUpdate {
	background: url(../public/images/btnIeUpdate.jpg) 0 0 no-repeat;
	width: 144px;
	height: 37px;
	position: absolute;
	right: 20px;
	bottom: 20px;
	color: #fff;
	line-height: 37px;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
	font-size: 13px;
}

.ieLogo {
	float: left;
	width: 134px;
	height: 139px;
	background: url(../public/images/ieLogo.jpg) no-repeat 0 0;
}

.ieContent {
	float: right;
	margin: 0;
	padding: 0;
	width: 350px;
	color: #666;
	font-size: 15px;
	line-height: 18px;
}

.ieContent strong {
	margin: 0 0 15px 0;
	padding: 0 0 10px 0;
	border-bottom: 1px dashed #cccccc;
	display: block;
	font-size: 18px;
	color: #333;
	font-weight: normal;
}
/*End IE below 9*/

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.outerMainWrapper{
	display: flex;
    position: relative;
    background-color: #fcfcfd;
    column-gap: 1px;
    min-height: 100vh;
}

.content-wrapper {
	background: #ffffff;
	box-shadow: 0px 0px 59px 0px rgba(157, 161, 180, 0.26);
	display: flex;
    flex-direction: column;
    justify-content: space-between;
	border-radius: 12px;
	padding: 25px 25px 25px 20px;
	margin-bottom: 15px;
}

.content-outer-wrapper {
	padding: 30px 30px 55px 30px;
	
}

button {
     border: none;
  cursor: pointer;
  appearance: none;
  background-color: inherit;
  font-family: 'Euclid Circular A';
}
.content-header {
	display: flex;
	padding: 0px 0 0px;
	justify-content: space-between;
	align-items: center;
}
.content-header h3 {
	color: #111111;
	font-size: 20px;
	font-weight: 600;
	padding-left: 20px;
}
.content-header-search {
	display: flex;
	column-gap: 10px;
}
.content-header-search .search-input{
	display: flex;
	column-gap: 30px;
	position: relative;
}
.content-header-search .search-input input[type=text] {
	padding: 0 15px ;
	max-width: 315px;
	width: 100%;
	height: 45px;
	border: 1px solid #e2e4e9;
	border-radius: 10px;
	font-size: 14px;
	font-family: 'Euclid Circular A';
}
.content-header-search .search-input select.search-date {
	background: url(../public/images/select-down-arrow.png) center right no-repeat;
	padding: 0 15px ;
	max-width: 170px;
	width: 100%;
	height: 45px;
	border: 1px solid #e2e4e9;
	border-radius: 10px;
	cursor: pointer;
	appearance: none;
	font-size: 15px;
	font-family: 'Euclid Circular A';
}

.buttoncover{
	display: flex;
    justify-content: space-between;
}
.deletebutton{

	padding: 0.3rem;
    background-color: #e41811;
    font-size: 18px;
    border: 1px solid#f00c0c;
    color: #ffffff;
    border-radius: 6px;
    display: block;
    width: 100px;
    text-align: center;
    cursor: pointer;


}
.cancelbutton{
	background-color: #0e72b3;
	border: 1px solid#0e72b3;
}

.bol-reject{
	background-color: #d8183b !important;
	border: 1px solid#d8183b !important;
	max-width: 105px;
    width: 100%;
    padding: 6px 10px;
    border-radius: 12px;
   display: flex;
    justify-content: center;
    font-size: 13px;
    font-weight: 500;
	cursor: pointer;
}

.bol-approve {
	background-color: #4CBB17 !important;
	border: 1px solid#6e9f51 !important;
	max-width: 105px;
    width: 100%;
    padding: 6px 10px;
    border-radius: 12px;
   display: flex;
    justify-content: center;
    font-size: 13px;
    font-weight: 500;
	cursor: pointer;
}

.content-header-search .search-input input::placeholder{ 
    font-size: 15px!important;
    color: #848591!important;
    font-family: 'Euclid Circular A';
    font-weight: normal;
  }
  
  /* Internet Explorer 10-11 */
.content-header-search .search-input input:-ms-input-placeholder, .table-data-enter input:-ms-input-placeholder{ 
    font-size: 15px!important;
    color: #848591!important;
    font-family: 'Euclid Circular A';
    font-weight: normal;
  }
  
  /* Microsoft Edge */
  .content-header-search .search-input input::-ms-input-placeholder { 
	font-size: 15px!important;
    color: #848591!important;
    font-family: 'Euclid Circular A';
    font-weight: normal;
  }
  .content-search-order-btns {
	display: flex;
	align-items: center;
  }
  .sort-btn {
    background-size: 28px 17px;
    width: 45px;
    height: 44px;
    border-radius: 10px;
    padding: 10px;
	margin-right: 10px;
	border: 1px solid #d7dbe5;
  }
  .asending-btn{
	background: url(../public/images/sort-icon-2.png) center center no-repeat, #eaebf6;
  }
 .desending-btn {
	background: url(../public/images/sort-icon-1.png) center center no-repeat, #eaebf6;
 }
  .sort-btn.desending-btn.active{
	background: url(../public/images/sort-icon-1-active.png) center center no-repeat, #eaebf6;
    background-size: 28px 17px;
	border: 1px solid #2f39a8;
  }

 .sort-btn.asending-btn.active {
	background: url(../public/images/sort-icon-2-active.png) center center no-repeat, #eaebf6;
    background-size: 28px 17px;
	border: 1px solid #2f39a8;
 }
  .more-option-btn {
	background: url(../public/images/more-option-dot.png) center center no-repeat;
	background-size: 5px 24px;
	padding: 10px;
	height: 45px;
	width: 45px;
  }
 
  .search-drop-down{
	font-weight: normal;
    padding: 0;
    border-radius: 10px;
    /* box-shadow: 0 0.15rem 1.75rem 0 rgb(146 147 152 / 15%) !important; */
    /* border-top-left-radius: 0; */
    /* border-top-right-radius: 0; */
    box-shadow: 3px 5px 50px rgb(0 0 0 / 10%) !important;
    border: 1px solid rgb(217 219 223);
    width: 170px;
    right: 0;
    background: rgb(252 252 252);
    top: 49px;
    box-sizing: border-box;
    position: absolute;
	cursor: pointer;
	display: none;
}
.cursor-pointer{
	cursor:pointer!important;
}
.search-drop-down ul {
	padding: 12px;
    margin: 0;
    overflow-x: auto;
    max-height: 385px;
}
.search-drop-down ul li {
	font-size: 15px;
	margin-bottom: 5px;
}
.error {
	font-size: 14px;
	font-weight: normal;
	margin-top:0px;
    color: red;
    margin-bottom: 10px;
}
input:focus, select:focus{
    outline: none;
}
.css-13cymwt-control {
	height: 53px;
	border-radius: 0!important;
    /* border: 1px solid #ccc; */
    border: 1px solid #9c9c9c!important;
}
.css-1u9des2-indicatorSeparator {
	display: none;
}
.css-1xc3v61-indicatorContainer {
	margin-top: -3px;
}
.css-1fdsijx-ValueContainer {
	height: 53px;
}
.css-t3ipsp-control:hover, .css-t3ipsp-control {
	border: 1px solid #9c9c9c!important;
	border-radius: 0!important;
	border-width: 0;
	box-shadow: none!important;
}
#react-select-2-list-box {
	position: relative;
	z-index: 900;
	
}
.css-1jqq78o-placeholder, .css-1dimb5e-singleValue {
	font-size: 15px!important;
    color: #111111!important;
    font-family: 'Euclid Circular A';
    font-weight: normal;
	padding: 0 16px;
}
.input-wrap.country-dropdown {
	z-index: 1000;
	margin-bottom: 20px;
}
/* .css-inmdiq5-menu {
	position: absolute!important;
	overflow: hidden;
} */
.css-19bb58m {
	margin: 0!important;
	padding: 0!important;
}
select option {
	font-family: 'Euclid Circular A'!important;
}
select, option {
	font: -moz-pull-down-menu;
	-webkit-appearance: none;  /* Remove style Chrome */
	-moz-appearance: none; /* Remove style FireFox */
	appearance: none; /* Remove style FireFox*/
  }
  @supports (-moz-appearance:none) {
  select
  {
  -moz-appearance:none !important;
  background: transparent url('data:image/gif;base64,R0lGODlhBgAGAKEDAFVVVX9/f9TU1CgmNyH5BAEKAAMALAAAAAAGAAYAAAIODA4hCDKWxlhNvmCnGwUAOw==') right center no-repeat !important;
  background-position: calc(100% - 5px) center !important;
  }
}
@media screen and (max-width: 1279px) {
	.content-outer-wrapper {
		padding: 30px 15px 55px 15px;
	}
}

@media screen and (max-width: 1300px) {
	.outerMainWrapper {
		flex-direction: column;
	}
}



.add-bol-popup .country-dropdown .stdropdown-tools, .add-company-popup .country-dropdown .stdropdown-tools {
	right: 30px;
    top: 40%;
}

.stsearch-box {

}

.stdropdown-input.stsearch-box input{
	border-radius: 0px;
}

.input-wrap.country-dropdown.active .css-13cymwt-control{
	border: 1px solid red!important;
	border-radius: 0px;
}
.input-wrap.country-dropdown.active .label_style {
	color:red!important
}

.warning {
	font-size: 14px;
	color: #0e72b3;
}