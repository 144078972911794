/* dashboard css start */

.count-box-wrapper {
    display: flex;
    column-gap: 25px;
    padding: 0px 0 30px;
    justify-content: space-evenly;
}
.count-box {
    max-width: 284px;
    width: 100%;
    border-radius: 12px;
    padding: 35px 30px;
}
.count-box.lightgreen {
    background: #e3f6f0;
}
.count-box.lightorange  {
    background :#fff0e9;
}

.count-box.lightblue {
    background: #e5f6ff;
}

.count-box-content {
    display: flex;
    column-gap: 10px;
}
.count-box-content h3 span{
    display: block;
    color: #111111;
    font-size: 18px;
    font-weight: normal;
    font-family: 'Euclid Circular A';
}
.count-box-content h3  {
    font-weight: 500;
    font-family: 'Euclid Circular A';
    font-size: 30px;
}
.count-box.lightgreen .count-box-content h3 {
    color: #00b074;
}
.count-box.lightorange .count-box-content h3 {
    color:#ff562d;
}
.count-box.lightblue .count-box-content h3{
    color:#2c71ff;
}
.round-box {
    width: 68px;
    height: 68px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.round-box.green-round {
    background: #bdeadb;
}
.round-box.orange-round {
   background:#ffdac9;
}
.round-box.blue-round {
    background: #b7e6ff;
}

.pending-status {
    color: #ffc000;
    cursor: pointer;
}
.draft-status {
    color: #0e72b3;
    cursor: pointer;
}
.rejected-status {
    color: #ea491d;
    cursor: pointer;
}
.approved-status {
    color: #5cad3c;
    cursor: pointer;
}
.add-company-btn, .add-contact-btn {
    display: flex;
    color: #0e72b3;
    font-size: 17px;
    font-weight: 500;
    font-family: 'Euclid Circular A';
    align-items: center;
    column-gap: 15px;
    margin-left: 40px;
}
.add-company-btn span, .add-contact-btn span {
    background-color: #0e72b3;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    color: #fff;
    font-size: 29px;
    font-weight: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 5px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 15px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #e7ebee;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 11px;
    width: 11px;
    left: 4px;
    bottom: 2px;
    background-color: #0e72b3;;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #0e72b3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #fff;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(23px);
    -ms-transform: translateX(23px);
    transform: translateX(23px);
    background-color: #fff;

  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  /* .toggle-btn-wrap {
    margin-left: 15px;
  } */

  .toggle-on {
    color: #0e72b3;
    font-weight: 500;
    font-size: 15px;
    margin-left: 15px;
  }
  .toggle-off{
    color: #8f9ba4;
    font-weight: normal;
    font-size: 15px;
    margin-left: 15px;
  }
  .width-50 {
    width: 50%;
  }
  .sno-width {
    width: 90px;
  }

  .dashboard-management .content-header-search .search-input {
    flex-grow: 1;
  }

  .dashboard-management .content-header-search {
    max-width: 690px;
    width: 100%;
  }
  @media screen and (max-width: 1400px)  {
    .content-header.dashboard-management {
      flex-direction: column;
      align-items: flex-start;
     }
  }
 
  
  /* dashboard css end */
  