.left-container {
	max-width: 375px;
    width: 100%;
    background: #0e72b3;
    padding: 50px 45px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #fff;
    box-sizing: border-box;
    transition: all 0.6s cubic-bezier(0.33, 1, 0.68, 1)
}
.leftmenu-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* margin: 0 15px 88px 15px; */
}
.leftmenu-top .logo {
    width: 190px;
    transition: all 0.6s cubic-bezier(0.33, 1, 0.68, 1);
}
.leftmenu-top .logo img {
    object-fit: cover;
    display: block;
	filter: brightness(0) invert(1);
}
.hamburger.icon {
	margin-top: 18px;
}

.left-menu-section {
    display: flex;
    flex-direction: column;
}
.leftmenu-top .hamburger.mobile-icon {
    display: none;
}

.left-menu {
	margin-top: 75px;
    transition: all 0.6s cubic-bezier(0.25, 1, 0.5, 1);
}
.left-menu ul {
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    font-weight: 500;
    padding: 0 0 30px;
    transition: all 0.6s cubic-bezier(0.25, 1, 0.5, 1);
}
.left-menu ul li {
	padding: 6px 0 6px;
    transition: all 0.6s cubic-bezier(0.25, 1, 0.5, 1);
}
.left-menu ul li a {
    height: 52px;
    background-color: transparent;
    display: flex;
    align-items: center;
    padding: 0 30px 0 30px;
    border-radius: 30px;
    color: #fff;
    transition: all 0.6s cubic-bezier(0.25, 1, 0.5, 1);
    position: relative;
	font-size: 17px;
	font-weight: normal;
}
.left-menu ul li a .menu-icon {
    margin-right: 30px;
    background: no-repeat center;
    background-size: contain;
    flex-shrink: 0;
    transition: all 0.6s cubic-bezier(0.25, 1, 0.5, 1);
	filter: brightness(0) invert(1);
	max-width: 30px;
    width: 100%;
}

.left-menu ul li a span {
    line-height: 18px;
    transition: all 0.6s cubic-bezier(0.33, 1, 0.68, 1);
}
.left-menu ul li > a.active, .left-menu ul li > a:hover {
    background: #fff;
    color: #0e72b3;
	filter: none;
}
.left-menu ul li > a.active .menu-icon, .left-menu ul li > a:hover .menu-icon{
	filter: none;
}
.leftmenu-top .hamburger.mobile-icon {
    display: none;
}
.footer-companycopyright {
	position: absolute;
    bottom: 61px;
	max-width: 215px;
	width: 100%;
    /* font-size: 17px; */
    color: #fff;
}
.footer-companycopyright a {
	font-size: 12px;
    color: #fff;
	font-family: 'Euclid Circular A';
	font-weight: 300;
}
.hamburger.icon {
	display: none;
}

/* ------------MEDIA QUERY----------------------------------- */
@media screen and (max-width: 1845px) {
	.left-container {
		max-width: 20.325vw;
		padding: 2.710vw 2.439vw;
	}

	.leftmenu-top .logo {
		width: 10.298vw;
	}
	.hamburger.icon {
		margin-top: 0.976vw;
	}
    .left-menu {
		margin-top: 4.065vw;
	}
	.left-menu ul {
		padding: 0 0 1.626vw;
	}
	
	.left-menu ul li a {
		/* height: 2.818vw; */
		padding: 0 1.626vw 0 1.626vw;
		/* border-radius: 1.626vw; */
		font-size: 16px;
	}
	.left-menu ul li a .menu-icon {
		margin-right: 1.626vw;
		max-width: 30px;
		width: 100%;
	}

}
@media screen and (max-width: 1650px) { 
    .leftmenu-top .logo {
		width: 170px;
	}
}

@media screen and (max-width: 1450px) {
	.left-container {
		max-width: 250px;
		padding: 50px 20px;
	}
    .leftmenu-top .logo {
		width: 148px;
	}
}

@media screen and (max-width: 1300px) {
	.leftmenu-top {
		column-gap: 28px;
	}
	.leftmenu-top .logo {
		width: 195px;
	}
	.leftmenu-top .logo {
		order: 2;
	}
	.leftmenu-top .hamburger.mobile-icon .menu-close {
		display: none;
	}
	nav.left-menu {
		height: 0;
		opacity: 0;
		display: none;
	}
	.left-container.mobile-toggle nav.left-menu {
		position: absolute;
		width: 100%;
		left: 0;
		background: #0e72b3;
		box-shadow: 0 2px 12px rgb(204 200 200 / 28%);
		top: 115px;
		padding: 20px 20px 0;
		box-sizing: border-box;
		z-index: 10000;
		display: block;
		height: auto;
    	opacity: 1;
		margin-top: 0;
	}
	.left-container {
		flex-direction: row;
		max-width: 100%;
		width: 100%;
		padding: 25px 10px 25px;
		min-height: auto;
	}
	.left-container.message-added{
		margin-top: 30px;
	}
	.left-menu-section {
		flex-direction: row;
		justify-content: space-between;
		flex-grow: 1;
		align-items: center;
	}
    .leftmenu-top .hamburger.icon {
		display: none;
	}
	.leftmenu-top .hamburger {
		order: 1;
	}
	.leftmenu-top .hamburger.mobile-icon {
		display: flex;
		width: 26px;
		align-self: center;
	}
	.leftmenu-top .hamburger.mobile-icon .menu-close {
		display: none;
	}
	.leftmenu-top .hamburger.mobile-icon.open .menu-close {
		display: block;
	}
    .left-menu ul li {
		max-width: 200px;
		width: 100%;
	}
	.leftmenu-top .hamburger.mobile-icon.open .menu-open {
		display: none;
	}
	.hamburger.icon {
		display: flex;
		cursor: pointer;
	}
}