.pagination-wrapper {
    padding: 25px 10px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.pagination-wrapper p {
    font-size: 15px;
    font-weight: 300;
    color: #5f637b;
}
.pagination-wrapper p span {
    font-weight: 500;
}
.pagination-next-prev-wrap ul {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: flex-end;
    margin: 0;
}
.pagination-next-prev-wrap ul li img {
    max-width: 13px;
    width: 100%;
    height: 13px;
}
.pagination-next-prev-wrap ul li {
    font-size: 16px;
    font-weight: normal;
    color: #56585f;
    margin-right: 20px;
    padding: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .3s ease-in-out;
    width: 35px;
    height: 35px;
}
.prev-page {
    padding-right: 30px;
}
.next-page {
    padding-left: 30px;
}
.pagination-next-prev-wrap ul li.active, .pagination-next-prev-wrap ul li:hover {
    background: #0e72b3;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}

.bol-view-pagination{
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.bol-view-pagination .pagination-wrapper{
    padding: 0px;
    justify-content: flex-end;
}

.bol-view-pagination .bol-approve-reject-btn{
    margin-top: 0px;
}
.bol-view-pagination div {
    flex-basis: 50%;
    flex-grow: 1;
}
