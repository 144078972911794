.page-center {
	max-width: 1620px;
	width: 100%;
	margin: 0 auto;
	/* padding: 65px 50px; */
	padding: 50px 50px;
}
.login-wrapper {
	display: flex;
}
.login-left {
	max-width: 760px;
	width: 100%;
	padding: 0 0 0 35px;
	position: relative;
}
.login-left h4 {
	font-size: 40px;
    line-height: 49px;
    color: #000000;
    font-weight: 600;
	padding: 60px 0 50px;
	font-family: 'Euclid Circular B';
}

.login-left .logo-text-content p {
	font-size: 24px;
	font-weight: 300;
	color: #848591;
	line-height: 36px;
	max-width: 420px;
    width: 100%;
	padding: 35px 0;
}
.login-left .login-copyright {
	font-size: 12px!important;
	font-weight: 300!important;
	font-family: 'Euclid Circular A'!important;
	color:#000000!important;
	line-height: 22px!important;
}
.logo-text-content {
	max-width: 530px;
	width: 100%;
}

.logo {
	max-width: 430px;
	width: 100%;
}
.login-right {
	flex-grow: 1;
}
.login-form-container {
	background: #edf0f6;
	border-radius: 20px;
	max-width: 765px;
	padding: 110px 130px 75px 150px;
	z-index: 99;
    position: relative;
}
.login-form-container h2 {
	font-size: 48px;
	line-height: 52px;
	font-weight: bold;
	color: #075083;
	padding-bottom: 20px;
}
.login-form-container h2 span{
	color:#0e72b3;
	padding-left: 10px;
}
.login-form-container h3 {
	font-size: 35px;
    color: #000000;
	line-height: 40px;
	font-family: 'Euclid Circular A';
	font-weight: normal;
	padding-bottom: 15px;
}
.login-form {
	margin-top: 25px;
}
.login-form .input-wrap label, .forgot-form .input-wrap label{
	font-size: 20px;
	font-family: 'Euclid Circular A';
	font-weight: normal;
	color: #000000;
}

.login-form input[type=email], .login-form input[type=password], .login-form input[type=text], .forgot-mail input[type=text] {
	padding: 0px 20px;
	height: 55px;
	max-width: 485px;
	width: 100%;
	background: #fff;
	border: none;
	border-radius: 10px;
	font-size: 20px;
    color: #848591;
	border: 1px solid #c8ccd2;
	font-family: 'Euclid Circular A';
}

.login-form .mail label, .login-form .pass label, .forgot-mail label {
	display: block;
	padding-bottom: 10px;
}

.login-action {
	display: flex;
    justify-content: flex-end;
}
.form-checkbox {
    position: relative;
    padding-left: 40px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
	font-size: 20px;
}
.form-checkbox input[type="checkbox"] {
    border: none;
    width: auto;
    position: absolute;
    left: 0;
    opacity: 0;
}
.form-checkbox .checkmark {
    width: 25px;
    height: 25px;
    position: absolute;
	background-color: white;
    border: 2px solid #000;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    cursor: pointer;
    border-radius: 6px;
}
.form-checkbox .checkmark::after {
    content: "";
    position: absolute;
	left: 6px;
    top: 0px;
    width: 7px;
    height: 16px;
    border: solid #0e72b3;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    display: none;
}
.form-checkbox input:checked ~ .checkmark::after {
    display: block;
}
.login-btn {
    margin-top: 30px;
    background-color: #0e72b3;
    display: flex;
    justify-content: center;
    cursor: pointer;
    border-radius: 10px;
    border: 2px solid #0e72b3;
    transition: .3s all ease-in-out;
	color:#fff;
	max-width: 490px;
	width: 100%;
	/* font-size: 25px;
    height: 65px; */
    font-size: 22px;
    height: 50px;
    font-weight: 500;
    padding: 0px 20px;
	transition: .3s all ease-in-out;
}
.left-img {
	position: absolute;
	right: -83px;
    bottom: 160px;
    z-index: 1000;
}
.login-form .input-wrap {
	margin-bottom: 25px;
}

.input-wrap input::placeholder { 
	font-size: 20px!important;
    color: #848591!important;
	font-weight: normal!important;
	font-family: 'Euclid Circular A';
  }
  .forgot-form .forgot-mail input::placeholder { 
	font-size: 20px!important;
    color: #848591!important;
	font-weight: normal!important;
	font-family: 'Euclid Circular A';
  }
  
  
  /* Internet Explorer 10-11 */
  .input-wrap input:-ms-input-placeholder { 
    font-size: 20px!important;
    color: #848591!important;
	font-weight: normal!important;
	font-family: 'Euclid Circular A';
  }
  .forgot-form .forgot-mail input:-ms-input-placeholder { 
    font-size: 20px!important;
    color: #848591!important;
	font-weight: normal!important;
	font-family: 'Euclid Circular A';
  }
  
  /* Microsoft Edge */
  .input-wrap input::-ms-input-placeholder { 
    font-size: 20px!important;
    color: #848591!important;
	font-weight:normal!important;
	font-family: 'Euclid Circular A';
  }
  .forgot-form .forgot-mail input::-ms-input-placeholder { 
    font-size: 20px!important;
    color: #848591!important;
	font-weight:normal!important;
	font-family: 'Euclid Circular A';
	-moz-appearance: none ;
  }
  .left-image-height {
    height: 380px;
  }
  .login-forgotpsswrd {
	font-size: 20px;
	color: #000000;
	transition: .3s all ease-in-out;
	cursor: pointer;
  }
  .login-btn:hover {
	background-color:#fff; 
	color:#0e72b3;
  }
  .login-forgotpsswrd:hover, .back-to-login:hover{
	color:#0e72b3;
  }
  .forgot-form input[type=submit]:hover {
	background-color:#fff; 
	color:#0e72b3;
	border : 1px solid #0e72b3;
  }
  
/* --------------Login-End-------------------------------------------- */

/* ------------Forgetpassword Popup----------------------------------------------------------- */
.forgotpasswrd-popupbg-overlay {
	background: rgb(0,0,0,25%);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
	z-index: 1999;
}
.forgotpasswrd-popupbox {
    position: absolute;
    top: 0;
    /* width: 100%; */
    padding: 100px 0;
    z-index: 2000;
    margin: auto;
    left: 0;
    right: 0;
    display: none;
}
.forgotpasswrd-popupbox-wrapper {
	background: #fff;
    padding: 45px 45px 60px;
    max-width: 425px;
    border-radius: 12px;
    width: 100%;
    margin: 0 auto;
    position: relative;
}
.forgotpasswrd-popupbox-close {
	position: absolute;
    right: 22px;
    top: 22px
}
.forgotpasswrd-popupbox-content .popup-logo{
	text-align: center;
    margin: 20px 0 40px;
}
.forgotpasswrd-popupbox-content .popup-logo img {
	max-width: 200px;
    width: 100%;
}
.forgotpasswrd-popupbox-content .popup-logo h4 {
	font-size: 22px;
    padding-top: 20px;
}
.forgot-form input[type="email"] {
	padding: 15px 20px;
    height: 55px;
    max-width: 485px;
    width: 100%;
    background: #fff;
    border: none;
    border-radius: 10px;
    font-size: 18px;
    margin-bottom: 25px;
    color: #848591;
    border: 1px solid #c8ccd2;
}
.forgot-form input[type=submit] {
	display: inline-flex;
    align-items: center;
    background: #0e72b3;
    font-family: 'Euclid Circular A';
    font-size: 23px;
    font-weight: 400;
    color: #fff;
    border: 0;
    border-radius: 8px;
    padding: 0 20px;
    width: 100%;
    max-width: 430px;
    height: 59px;
    margin-bottom: 20px;
    justify-content: center;
    cursor: pointer;
	transition: .3s all ease-in-out;
}
.back-to-login {
	text-align: center;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    font-size: 19px;
    color: #000;
	transition: .3s all ease-in-out;
	cursor: pointer;
}

.login-left{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
 }

 .logo-img-container{
	padding: 70px 0 0 0px;
 }

 /* -----------Media Query--------------------------------------------------------------- */


 @media screen and (max-width: 1650px) {
	.page-center {
		/* padding: 3.939vw 3.030vw; */
		padding: 3.030vw 3.030vw;
	}
	.login-left {
		max-width: 46.061vw;
	}
	.logo {
		max-width: 26.06vw;
	}
	.left-img {
		max-width: 30.424vw;
		width: 100%;
	}
	.left-image-height {
		height: 24.03vw;
	}
	.login-form-container {
		max-width: 46.364vw;
		padding: 6.667vw 7.879vw 4.545vw;
	}
	.login-left h4 {
		font-size: 2.424vw;
		line-height: 2.970vw;
		padding: 3.636vw 0 3.030vw;
	}
	.logo-text-content {
		max-width: 32.121vw;
	}
	.login-left .logo-text-content p {
		font-size: 1.455vw;
		line-height: 2.182vw;
		max-width: 25.455vw;
	}
	.login-form-container h2 {
		font-size: 3.091vw;
		line-height: 3.091vw;
	}
	.login-form-container h3 {
		font-size: 2.121vw;
		line-height: 2.424vw;
	}
	.login-form {
		margin-top: 2.121vw;
	}
	.login-form .input-wrap label, .forgot-mail label, .forgot-form .input-wrap label {
		font-size: 1.212vw;
	}
	/* .login-form .mail label, .login-form .pass label {
		padding-bottom: 1.333vw;
	} */
	.login-form input[type=email], .login-form input[type=password], .login-form input[type=text], .forgot-mail input[type=text] {
		padding: 0 1.212vw;
		/* height: 3.939vw; */
		height:3.333vw;
		max-width: 29.394vw;
		font-size: 1.212vw;
	}
	.login-btn {
		margin-top: 2.424vw;
		max-width: 29.697vw;
		font-size: 1.333vw;
		height:3.030vw;
	}
	.form-checkbox {
		padding-left: 2.424vw;
		font-size: 1.212vw;
	}
	/* .login-forgotpsswrd {
		font-size: 1.212vw;
	} */
	.input-wrap input::placeholder { 
		font-size: 1.212vw!important;
		color: #848591!important;
		font-weight: normal!important;
	  }
	  .forgot-form .forgot-mail input::placeholder { 
		font-size: 1.212vw!important;
		color: #848591!important;
		font-weight: normal!important;
	  }
	  
	  /* Internet Explorer 10-11 */
	  .input-wrap input:-ms-input-placeholder { 
		font-size: 1.212vw!important;
		color: #848591!important;
		font-weight: normal!important;
	  }
	  .forgot-form .forgot-mail input:-ms-input-placeholder {
		font-size: 1.212vw!important;
		color: #848591!important;
		font-weight: normal!important;
	  }
	  /* Microsoft Edge */
	  .input-wrap input::-ms-input-placeholder { 
		font-size: 1.212vw!important;
		color: #848591!important;
		font-weight: normal!important;
	  }
	  .forgot-form .forgot-mail input::-ms-input-placeholder { 
		font-size: 1.212vw!important;
		color: #848591!important;
		font-weight: normal!important;
	  }
 }
 @media screen and (max-width: 1400px) {
	.page-center {
		padding: 60px 40px;
	}
	.login-left {
		max-width: 550px;
		padding: 0;
	}
	.logo {
		max-width: 390px;
	}
	.logo-text-content {
		max-width: 460px;
	}
	.login-left h4 {
		font-size: 35px;
		line-height: 48px;
		padding: 42px 0 32px;
	}
	.login-form-container {
		max-width: 765px;
		padding: 80px 70px 65px 95px;
	}
	.login-left h4 {
		font-size: 37px;
		line-height: 45px;
		padding: 45px 0 35px;
	}
	.login-left .logo-text-content p {
		font-size: 22px;
		line-height: 32px;
		max-width: 400px;
	}
	.left-image-height {
		height: 300px;
	}
	.left-img {
		max-width: 370px;
		right: -59px;
		/* bottom: 84px; */
	}
	.login-left .login-copyright {
		font-size: 12px!important;
	}
	.login-form-container h2 {
		font-size: 38px;
		line-height: 44px;
		padding-bottom: 15px;
	}
	.login-form-container h3 {
		font-size: 30px;
		line-height: 36px;
		
	}
	.login-form {
		margin-top: 20px;
	}
	/* .login-form .mail label, .login-form .pass label {
		padding-bottom: 18px;
	} */

	.login-form input[type=email], .login-form input[type=password], .login-form input[type=text], .forgot-mail input[type=text] {
		max-width: 100%;
		padding: 0px 20px;
		height: 50px;
		font-size: 16px;
	}
	.login-form .input-wrap label, .forgot-mail label, .forgot-form .input-wrap label {
		font-size: 17px;
	}
	.form-checkbox {
		font-size: 19px;
		padding-left: 40px;
	}
	.login-forgotpsswrd {
		font-size: 17px
	}
	.input-wrap input::placeholder { 
		font-size: 16px!important;
		color: #848591!important;
		font-family: 'Euclid Circular A';
		
	  }
	  .forgot-form .forgot-mail input::placeholder {
		font-size: 16px!important;
		color: #848591!important;
		font-family: 'Euclid Circular A';
	  }
	  
	  
	  /* Internet Explorer 10-11 */
	  .input-wrap input:-ms-input-placeholder { 
		font-size: 16px!important;
		color: #848591!important;
		font-family: 'Euclid Circular A';
	  }
	  .forgot-form .forgot-mail input:-ms-input-placeholder { 
		font-size: 16px!important;
		color: #848591!important;
		font-family: 'Euclid Circular A';
	  }
	  
	  /* Microsoft Edge */
	  .input-wrap input::-ms-input-placeholder { 
		font-size: 16px!important;
		color: #848591!important;
		font-family: 'Euclid Circular A';
	  }
	  .forgot-form .forgot-mail input::-ms-input-placeholder { 
		font-size: 16px!important;
		color: #848591!important;
		font-family: 'Euclid Circular A';
	  }
	  .login-btn {
		font-size: 20px;
    	height: 50px;
		margin-top: 25px;
		max-width: 100%;
	  }
	  .logo {
		max-width: 330px;
	}
	.logo-img-container{
		padding: 100px 0 0 0px;
	 }

 }
 @media screen and (max-width: 1280px){
	.logo-img-container{
		padding: 100px 0 0 0px;
	 }
 }

 @media screen and (max-width: 1150px) {
	.login-left {
		max-width: 490px;
	}
	.logo {
		max-width: 280px;
	}
	.logo-text-content {
		max-width: 420px;
	}
	.login-left h4 {
		font-size: 30px;
		line-height: 42px;
		padding: 35px 0 35px;
	}
	.login-left .logo-text-content p {
		font-size: 20px;
		line-height: 32px;
		max-width: 400px;
	}
	.left-img {
		max-width: 340px;
		right: -37px;
		/* bottom: 63px; */
	}
	.left-image-height {
		height: 400px;
	}
	.login-form-container {
		max-width: 765px;
		padding: 65px 45px 65px 75px;
	}
	.login-form-container h2 {
		/* font-size: 40px;
		line-height: 52px; */
		padding-bottom: 15px;
	}
	.login-form-container h3 {
		font-size: 28px;
		line-height: 37px;
	}
	
	.login-action {
		flex-direction: column;
		align-items: flex-end;
	}
	.login-forgotpsswrd {
		margin-top: 10px;
	}
	.logo-img-container{
		padding: 120px 0 0 0px;
	 }
 }
 @media screen and (max-width: 1000px) {
	.login-wrapper {
		flex-direction: column-reverse;
	}
	.login-form-container {
		margin: auto;
		padding: 65px 55px 65px 55px
	}
	.login-left {
		margin: auto;
		text-align: center;
		padding-top: 30px;
	}
	.logo-text-content {
		margin: auto;
	}
	.left-img {
		right: 50%;
		bottom: 53px;
		transform: translateX(50%);
	}
	.left-img{
		position: relative;
		margin-top: 100px;
		max-width: 100%;
	}

	.logo-img-container {
		padding: 70px 0 0 0px;
	}
 }