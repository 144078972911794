.all-list-bol .content-header-search {
    max-width: 690px;
    width: 100%;
}
.all-list-bol .content-header-search .search-input {
    flex-grow: 1;
}
@media screen and (max-width: 1400px)  {
    .content-header.all-list-bol {
        flex-direction: column;
        align-items: flex-start;
    }
    .all-list-bol .content-header-search {
        margin-top: 20px;
        padding-left: 20px;
    }
}
