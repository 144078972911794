





.country-dropdown .stdropdown-input{
	position: relative;
	padding: 0;
}

.country-dropdown  .stdropdown-tools{
	position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
}

.country-dropdown  .stdropdown-tools svg{
    fill: #9da3b3;
    width: 17px;
    height: 17px;
}

.country-dropdown  .stdropdown-tools svg path {
    stroke-width: 1px;
  }

.country-dropdown  .stdropdown-menu {
    transform: translateY(-10px);
    z-index: 999;
}

.country-dropdown  .stdropdown-container{
    border: none;
}
.popupbg-overlay {
	background: rgb(0,0,0,25%);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

	z-index: 1999;
}

.popup-box {
    position: absolute;
    top: 0;
    /* width: 100%; */
    /* padding: 100px 0; */
    padding: 60px 0;
    z-index: 2000;
    margin: auto;
    left: 0;
    right: 0;

}

.popup-box.warning-popup {
    position: absolute;
    padding: 60px 0;
    z-index: 2000;
    margin: auto;
    left: 50%;
    top: 320px;
    transform: translate(-50%,0%);
    text-align: center;
}

.ok-btn {
	max-width: 113px;
    width: 100%;
    height: 52px;
    /* float: right; */
    font-size: 18px;
    font-weight: normal;
    background-color: #0e72b3;
    border-radius: 6px;
    border: 1px solid#0e72b3;
    color: #ffffff;
    margin-top: 10px;
	
}

.popupbox-wrapper {
	background: #fff;
    /* padding: 45px 45px 60px; */
    /* padding: 45px 70px 60px; */
    padding: 45px 55px 45px;
    max-width: 503px;
    border-radius: 12px;
    width: 100%;
    margin: 0 auto;
    position: relative;
}
.popupbox-close {
	position: absolute;
    right: 22px;
    top: 22px;
    cursor: pointer;
}

.popupbox-content h4 {
    font-size: 26px;
    font-weight: 600;
    color: #111111;
    padding-bottom: 40px;
}

.popupbox-content h6 {
    font-size: 17px;
    color: #111111;
    padding-bottom: 20px;
    padding-left: 30px;
}

.popupbox-content h3 {
    font-size: 26px;
    color: #111111;
    padding-bottom: 20px;
    font-weight: 600;
    padding-left: 55px;
}

.popupbox-content .input-wrap input {
    /* max-width: 373px; */
    width: 100%;
    height: 52px;
    /* border-radius: 6px; */
    border: 1px solid #9c9c9c;
    padding: 0 25px;
    margin-bottom: 20px;
    -webkit-appearance: none;
    font-size: 15px;
    font-family: 'Euclid Circular A';
}
.popupbox-content .input-wrap textarea {
    /* max-width: 373px; */
    width: 100%;
    height: 45px;
    border: 1px solid #9c9c9c;
    padding: 14px 25px;
    margin-bottom: 12px;
    font-family: 'Euclid Circular A';
    resize: none;
    overflow: hidden;
    min-height: 52px;
    /* font-size: 17px; */
    font-size: 15px;
}

.popupbox-content .input-wrap.approve-reject-popup-textarea textarea {
    max-width: 100%;
    width: 100%;
    min-height: 100px;
    border: 1px solid #9c9c9c;
    padding: 14px 25px;
    margin-bottom: 12px;
    font-family: 'Euclid Circular A';
    resize: none;
    overflow: auto;
    font-size: 17px;
}

.popupbox-content .submit-wrap input {
    max-width: 113px;
    width: 100%;
    height: 52px;
    font-size: 18px;
    font-weight: normal;
    background-color: #0e72b3;
    border-radius: 6px;
    border: 1px solid#0e72b3;
    color: #ffffff;
    margin-top: 10px;
    cursor: pointer;
    font-family: 'Euclid Circular A';
}
.popupbox-content .input-wrap.popup-textarea textarea{
    max-height: 140px;
    }

.alert-popup-btn{
    padding-left: 60px;
}
/* .input-wrap.approve-reject-popup-textarea textarea{
    min-height:  101px;
    overflow: scroll;
} */

.popupbox-content .alert-cancel-btn {
    max-width: 113px;
    width: 100%;
    height: 52px;
    font-size: 18px;
    font-weight: normal;
    background-color: #0e72b3;
    border-radius: 6px;
    border: 1px solid#0e72b3;
    color: #ffffff;
    margin-top: 10px;
}
.popupbox-content .alert-delete-btn {
    max-width: 113px;
    width: 100%;
    height: 52px;
    float: right;
    font-size: 18px;
    font-weight: normal;
    background-color: #e41811;
    border-radius: 6px;
    border: 1px solid#f00c0c;
    color: #ffffff;
    margin-top: 10px;
    margin-right: 55px;
}

.popupbox-content .input-wrap input::placeholder, .popupbox-content .input-wrap textarea::placeholder { 
    font-size: 15px!important;
    color: #848591!important;
    font-weight: normal;
    font-family: 'Euclid Circular A';
  }
  
  /* Internet Explorer 10-11 */
  .popupbox-content .input-wrap input:-ms-input-placeholder, .popupbox-content .input-wrap textarea:-ms-input-placeholder { 
    font-size: 15px!important;
    color: #848591!important;
    font-weight: normal;
    font-family: 'Euclid Circular A';
  }
  
  /* Microsoft Edge */
  .popupbox-content .input-wrap input::-ms-input-placeholder, .popupbox-content .input-wrap textarea::-ms-input-placeholder { 
    font-size: 15px!important;
    color: #848591!important;
    font-weight: normal;
    font-family: 'Euclid Circular A';
  }

  .popupbox-content .confirm-cancel-btn {
    max-width: 113px;
    width: 100%;
    height: 52px;
    font-size: 18px;
    font-weight: normal;
    background-color: #d8183b;
    border-radius: 6px;
    border: 1px solid #d8183b;
    color: #ffffff;
    margin-top: 10px;

    
}


.popupbox-content .confirm-submit-btn {
    max-width: 113px;
    width: 100%;
    height: 52px;
    float: right;
    font-size: 18px;
    font-weight: normal;
    background-color: #0e72b3;
    border-radius: 6px;
    border: 1px solid#0e72b3;
    color: #ffffff;
    margin-top: 10px;
    /* margin-right: 55px; */

}

/* ----------profile--start---------------------------- */

.two-column-popup .popupbox-wrapper {
    max-width: 710px;
}
.two-column-popup .popupbox-content .input-wrap {
    width: 100%;
}
.two-column-popup .popupbox-content .input-wrap input, .two-column-popup .popupbox-content .input-wrap textarea {
    max-width: 100%;
}
.two-column-popup .popupbox-content .input-wrap textarea {
    min-height: 91px;
}
.input-wrap-flex {
    display: flex;
    column-gap: 15px;
    width: 100%;
}
.password-management h4 {
    font-size: 15px;
    color: #111111;
    font-weight: 500;
    font-family: 'Euclid Circular A';
    padding-top: 13px;
    padding-bottom: 15px;
}
.image-input {
    position: relative;
    width: auto;
    display: inline-block;
    margin-bottom: 20px;
}
/* .preview-img {
    width: 100%;
    height: 70px;
    object-fit: cover;
} */
.image-input > span {
    max-width: 410px;
    max-height: 410px;
    overflow: hidden;
    display: block;
}
.image-input.round > span {
    background: #f4f4f4;
    border-radius: 50%;
    width: 65px;
    height: 65px;
    overflow: hidden;
    border: 0px solid #0e72b3;
}
.image-input.round > span img{
    border-radius: 50%;
    object-fit: cover;
    object-position: top;
    width: 100%;
    height: 100%;
    transition: all 0.6s cubic-bezier(0.25, 1, 0.5, 1);
}
.choose-file {
    position: absolute;
    right: 18px;
    top: 15px;
    width: 55px;
    height: 55px;
    cursor: pointer;
    display: flex;
    margin: 0;
}
/* .deleteprofile{
    position: absolute;
    right: -43px;
    top: 37px;
} */
.choose-file {
    right: 14px!important;
}
.choose-file img {
    max-width: 76px;
    width: 100%;
    height: 38px;
    background: #FFF;
    border-radius: 67px;
    padding: 2px;
    width: 35px;
    height: 35px;

}
label.choose-file {
    top: auto;
    bottom: -23px;
    right: 10px;
    /* left: 45px; */
}



.choose-file input {
    visibility: hidden;
}
.input-wrap{
    position:relative;
}
.label_style{
    position: absolute;
    top: -10px;
    left: 18px;
    background: #fff;
    padding: 0rem 0.5rem;
    color: #808080;
    font-weight: 500;
    font-size: 14px;
    z-index: 100;
}



/* style updated on 30-08-2023 */
.popup-chat-box {
    position: fixed;
    top: 50%;
    /* left:50%; */
    transform: translateY(-50%);
    /* width: 100%; */
    /* padding: 100px 0; */
    z-index: 2000;
    display: block;
    /* height: 80vh; */
    max-width: 330px;
    width: 100%;
    box-shadow: 0px 5px 100px 0px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0px 5px 100px 0px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0px 5px 100px 0px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    overflow: hidden;
    right: -400px;
    transition:all ease .3s ;
}

.popup-chat-box.active {
    right: 12px;
}

/* style updated on 30-08-2023 ends */

.popup-chat-box .popupbox-wrapper,
.popup-chat-box .popupbox-content,
.popup-chat-box .message-popup,
.popup-chat-box .message-body
{
    height: 100%;
}


.popup-chat-box .popupbox-wrapper {
    max-width: unset;
    width: unset;
}


.popupbox-wrapper {
	background: #fff;
    /* style updated on 30-08-2023 */
    /* padding: 45px 45px 60px;
    padding: 45px 70px 60px; */
    /* style updated on 30-08-2023 ends */
    max-width: 503px;
    border-radius: 12px;
    width: 100%;
    margin: 0 auto;
    position: relative;
}

/* style updated on 30-08-2023 */
.popup-chat-box .popupbox-content h4{
    font-size: 13px;
    color: #fff;
    padding-bottom:0 ;
}
/* style updated on 30-08-2023 ends */



/* style updated on 30-08-2023 */
.message-display{
    display: grid;
    overflow: auto;
    align-items: start;
    align-content: flex-start;
    padding: 30px 30px 30px 35px;
    /* flex-grow: 1; */
      /* Custom scrollbar styles for WebKit-based browsers (Chrome, Safari) */
    scrollbar-width: thin !important; /* Width of the scrollbar track */
    scrollbar-color: #888 #ca1515 !important; /* Scrollbar thumb color and track color */

    /* Optional: Hover Effect for Thumb */
    scrollbar-color: #555 #f1f1f1 !important; /* Hovered scrollbar thumb color */
}
/* style updated on 30-08-2023 ends */

.popup-chat-box .message-body{
    display: flex;
    flex-direction: column;
}

/* style updated on 30-08-2023 */
.send-msg{
    display: flex;
    clear: both;
    padding: 15px 18px;
    border-radius: 20px 20px 20px 0;
    background: #0e72b3;
    /* margin: 35px 0 0; */
    font-size: 13px;
    font-weight: normal;
    line-height: 18px;
    /* margin-left: 35px; */
    position: relative;
    min-height: 50px;
    align-items: center;
    color: #fff;
    width: 100%;
}
/* style updated on 30-08-2023 ends */

/* style updated on 30-08-2023 */
.message-wrap-send{
    justify-self: flex-start;  
    max-width: 90%;
    width: 100%;
    margin-bottom:20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.message-wrap-reply{
    justify-self: flex-end;  
    max-width: 90%;
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.message-wrap-send .timestamp,.message-wrap-reply .timestamp{
    padding-top: 8px;
    position: relative;
    font-size: 8px;
    color: #606784;
    font-weight: 300;
}

/* style updated on 30-08-2023 ends */
.message-wrap-send .timestamp{
    left: 2px;
}

.message-wrap-reply .timestamp{
    right: 2px;
}

.send-msg:first-child{
    margin-top: 0;
}

.send-msg.reply {
    justify-self: flex-end;
    /* background: linear-gradient(120deg, #ddd, #eee); */
    background: #f2f3f8;
    border-radius: 20px 20px 0 20px;
    color: #545766;
}

.send-msg.reply .timestamp {
    right: 0;
}

.send-msg .timestamp{
    position: absolute;
    bottom: -27px;
    font-size: 16px;
    color: #606784;
    left: 0px;
    font-weight: 300;
}

/* style updated on 30-08-2023 */
.message-input {
    background: none;
    border: none;
    outline: none !important;
    resize: none;
    /* color: rgba(255, 255, 255, .8); */
    font-size: 15px;
    height: 20px;
    margin: 10px 0 5px 25px;
    /* padding-right: 20px; */
    width: 70%;
    color: #444;
    font-family: 'Euclid Circular A';
    font-weight: 300;
    font-size: 13px;
    line-height: 12px;
    max-height: 110px;
    overflow: auto !important;
}

.message-box-input {
    width: 100%;
    background: #fff;
    margin: 0px auto 0;
    padding: 5px;
    position: relative;
    border-radius: 36px;
    min-height: 40px;
    max-height: 130px;
    border: 1px solid #f1f1f1;
    display: flex;
    align-items: center;
  }
/* style updated on 30-08-2023 ends */

  .message-box-input textarea:focus:-webkit-placeholder {
    color: transparent;
  }
  /* style updated on 30-08-2023 */
  .message-box-input .message-submit {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    color: #4A90E2;
    border: none;
    /* background: #c29d5f;*/
    background: #0e72b3;
    font-size: 12px;
    text-transform: uppercase;
    line-height: 1;
    padding: 6px 10px;
    border-radius: 5px;
    outline: none !important;
    transition: background 0.2s ease;
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  .message-box-input .message-submit::before {
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background: url(../../../public/images/send-icon.png) no-repeat 0 0;
    background-size: cover;
    width: 17px;
    height: 17px;
  }

  .message-header{
    align-items: center;
    justify-content: space-between;
    background: #0e72b3;
    padding: 18px 25px 18px 32px;
  }

  .message-typing-box{
    padding: 30px;
    box-shadow: 0px 5px 101px 0px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0px 5px 101px 0px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0px 5px 101px 0px rgba(0, 0, 0, 0.08);
  }

  .popup-close{
    cursor: pointer;
  }

  .message-display {
    max-height: 450px;
    min-height: 250px;
  }

  .message-btn{
    display: flex;
    background: #0e72b3;
    color: #fff;
    padding: 10px 15px;
    position: fixed;
    top: 50%;
    right: -45px;
    transform: translateY(-50%) rotate(270deg);
    border-radius: 10px 10px 0 0;
    gap: 7px;
    cursor: pointer;
    transition: all ease .3s;
    font-size: 15px;
    font-weight: 600;
    align-items: center;
  }

  .message-btn.clicked{
    right: 25px;
  }

  .message-btn img{
    max-width: 25px;
  }

  .message-display div:last-child{
    margin-bottom: 0 !important;
  }

  /* style updated on 30-08-2023 ends */

  @media screen and (max-width:1400px) {

    /* style updated on 30-08-2023 */
    .popupbox-wrapper {
        /* padding: 25px 30px 25px; */
    }

    .message-display {
        max-height: 320px;
        padding: 20px 20px 20px 25px;
    }

    .message-header {
        padding: 15px 20px 15px 25px;
    }

    .message-typing-box {
        padding: 20px;
    }
    
    

    .send-msg {
        padding: 7px 15px;
        /* margin: 25px 0 0; */
        font-size: 13px;
        min-height: 35px;
    }

    .message-wrap-send, .message-wrap-reply{
        margin-bottom:15px;
    }

    .message-input {
        font-size: 13px;
        height: 15px;
        max-height: 70px;
        margin: 5px 0px 5px 10px;
        width: 82%;
        line-height: 12px;
    }

    .message-wrap-send .timestamp,.message-wrap-reply .timestamp{
        /* font-size: 13px; */
        padding-top: 5px;
    }

    .popup-chat-box form{
        height: 88%;
    }

    .message-box-input {
        min-height: 40px;
    }

    .message-box-input .message-submit {
        /* width: 40px;
        height: 40px; */
    }

    .popup-chat-box {
        /* height: 85vh; */
        /* max-width: 500px; */
    }

    .message-box-input .message-submit::before{
        width: 12px;
        height: 12px;
    }
    /* style updated on 30-08-2023 ends */

    .popupbox-content h4 {
        padding-bottom: 20px;
    }
    
    .popupbox-close {
        right: 30px;
    }
    
  }

/* Custom scrollbar track */
.message-display::-webkit-scrollbar {
  width: 3px; /* Width of the scrollbar track */
}

/* Custom scrollbar thumb */
.message-display::-webkit-scrollbar-thumb {
  background-color: #0e72b3; /* Scrollbar thumb color */
  border-radius: 2px; /* Border radius of the thumb */
}

/* Optional: Hover Effect for Thumb */
.message-display::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Hovered scrollbar thumb color */
}

/* Custom scrollbar track */
.message-display::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Scrollbar track color */
}


.message-heading {
    text-align: center;
    margin-bottom: 10px;
    position: relative;
}
.message-heading span{
    background: #fff;
    z-index: 1;
    position: relative;
    padding: 0 8px;
}


.message-heading::before{
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background: #c9c9c9;
    left: 0px;
    top: 50%;
    position: absolute;
}

.message-heading::after{
    /* content: ""; */
    display: block;
    width: 27px;
    height: 2px;
    background: #c9c9c9;
    right: 18px;
    top: 50%;
    position: absolute;
}


