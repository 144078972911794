
.loader-overlay {
	background: rgb(0,0,0,10%);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    z-index: 1999;
}

.loader-overlay.show{
	display: block;
}

.spinner {
	width: 55px;
	height: 55px;
	border-radius: 50%;
	-webkit-mask: radial-gradient(farthest-side,#0000 40%,#0e72b3 41%);
	background: linear-gradient(0deg ,rgba(14,114,179,0.5) 50%,rgba(14,114,179,1) 0) center/4.5px 100%,
		 linear-gradient(90deg,rgba(14,114,179,0.25) 50%,rgba(14,114,179,0.75) 0) center/100% 4.5px;
	background-repeat: no-repeat;
	animation: spinner-d3o0rx 1s infinite steps(12);
	position: fixed;
	top: 50%;
	left: 50%;
	z-index: 2000;
	display: none;
 }

 .spinner.show {
	display: grid;
 }
 
 .spinner::before,
 .spinner::after {
	content: "";
	grid-area: 1/1;
	border-radius: 50%;
	background: inherit;
	opacity: 0.915;
	transform: rotate(30deg);
 }
 
 .spinner::after {
	opacity: 0.83;
	transform: rotate(60deg);
 }
 
 @keyframes spinner-d3o0rx {
	100% {
	   transform: rotate(1turn);
	}
 }

 .notification-popup .spinner, .notification-popup .loader-overlay{
	position: absolute;
 }


 .spinner-wrapper{
	position: absolute;
	top: 40%;
    left: 45%;
    transform: translate(-50%, -50%);
 }

 .spinner-wrapper .spinner {
    width: 40px;
    height: 40px;
 }