.admin-login .login-form-container {
    max-width: 540px;
    /* padding: 60px 50px 50px 60px; */
    padding: 45px 50px 45px 60px;
    margin: auto;
}
.admin-logo {
    text-align: center;
    padding-bottom: 20px;
}
.admin-logo .logo {
    /* max-width: 340px; */
    max-width: 240px;
    width: 100%;
}