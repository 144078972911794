
  
  .react-datepicker-popper {
    width:300px;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  
    width: 2.2rem !important;
    
}

/* .newbol-form-box .react-datepicker__day-name, .react-datepicker__day, .newbol-form-box .react-datepicker__time-name {
	width:2.2rem !important;
} */


.react-datepicker__month-container,.react-datepicker {
	width:100%;
}
.addrow{
	font-size: 16px;
    font-weight: normal;
    color: #0e72b3!important;
    display: flex;
    align-items: center;
	cursor: pointer;
}

.new-bol-input-wrap .error {
	font-size: 15px;
    font-weight: normal;
    margin-top: -1px;
    color: red;
    padding: 2px 12px;
    border: 1px solid red;
    max-width: 100%;
    display: flex;
    border-radius: 5px;
    align-items: center;
    line-height: 24px!important;
    background: red;
    color: #fff!important;
    position: absolute;
    max-width: 58%;
    width: 100%;
    top: 17px;
    left: 12px;
}
.errormessage{
		padding: 5px;
		border: 1px solid red;
		margin: 15px 0 0;
		position: relative;
		flex-grow: 1;
}

.new-bol-input-wrap .error::before {
	content: "";
    position: absolute;
    bottom: 100%;
    left: 15px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent red transparent;
}



.table-mini .table-data-enter textarea{
	min-height: 70px;
 }
 .table-outer{
	overflow: auto;
  }
  .submit-hover:hover {
	background-color:#fff!important; 
	color:#0e72b3!important;
}

.datepicker {
	width:300px;
	font-size: 16px;
	height:40px
    
}
.errormsg{
	color: red!important;
}
.form-checkbox {
    position: relative;
    padding-left: 40px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
	font-size: 20px;
}
.form-checkbox input[type="checkbox"] {
    border: none;
    width: auto;
    position: absolute;
    left: 0;
    opacity: 0;
}
.form-checkbox .checkmark {
    width: 25px;
    height: 25px;
    position: absolute;
	background-color: white;
    border: 2px solid #000;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    cursor: pointer;
    border-radius: 6px;
}
.form-checkbox .checkmark::after {
    content: "";
    position: absolute;
	left: 6px;
    top: 0px;
    width: 7px;
    height: 16px;
    border: solid #0e72b3;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    display: none;
}
.form-checkbox input:checked ~ .checkmark::after {
    display: block;
}

.content-wrapper.newbol-tab {
	background: #ffffff;
	box-shadow: 0px 0px 59px 0px rgba(157, 161, 180, 0.26);
	display: flex;
    flex-direction: column;
    justify-content: space-between;
	border-radius: 12px;
	padding: 15px 25px 15px 20px;
	margin: 0px auto 15px;
	max-width: 1180px;
}

.new-bol-header {
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;
	cursor: pointer;
	flex-wrap: wrap;
	gap: 10px;
}

h3.bol-create-message{
	background-color: #d6ebf0;
	color: #0041a5;
	/* border-radius: 5px; */
    text-align: center;
	padding: 5px 10px 5px 10px;
	display: flex;
    gap: 10px;
	justify-content: center;
}
.new-bol-header .version-bol-num {
	display: flex;
    align-items: center;
	column-gap: 45px;
}
.version-bol-num p {
	color: #111111;
	font-size: 22px;
	font-weight: 600;
	display: flex;
}
.new-bol-version-num {
	color:#ffffff;
	font-size: 19px;
	background-color: #0e72b3;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
	margin-right: 15px;
}
.version-bol-num h5 {
	font-size: 15px;
	font-weight: normal;
	white-space: nowrap;
}
.newbol-msg-btn {
	max-width: 165px;
    width: 100%;
    background: url(../../../public/images/chat-icon.png) 8% center no-repeat, #ebf3f8;
    padding: 15px 10px 15px 35px;
    border-radius: 15px;
    font-size: 17px;
    color: #0e72b3;
    display: inline-flex;
    justify-content: center;
    position: relative;
    border: none;
}
.newbol-msg-notifywrap {
	display: flex;
    align-items: center;
    /* width: 100%; */
	/* column-gap: 45px; */
	column-gap: 15px;
	justify-content: flex-end;
	flex-grow: 1;
}

.newbol-msg-notifywrap .bol-view-dropdown{
    /* width: 45px;
	height: 45px; */
}

.newbol-msg-notifywrap .bol-view-dropdown.menu-icon-active{
 
	transform: rotate(180deg);
}

.newbol-msg-btn.active::after {
	content:"";
	position: absolute;
	top: 0;
	right: 0;
	width: 8px;
	height: 8px;
	background: #ff6000;
	display: block;
	border-radius: 50%;
}
.clear-btn {
	max-width: 105px;
	width: 100%;
	padding: 6px 10px;
	border: 1px solid #0e72b3;
	border-radius: 12px;
	display: flex;
	justify-content: center;
	color: #0e72b3;
	font-size: 13px;
	font-weight: 500;
	margin: 15px 0;
	transition: all .3s ease-in-out;
}

.bol-view-back-btn{
	display: flex;
    justify-content: flex-end;
    align-items: center;
	flex-grow: 1;
}
.bol-view-back-btn-wrapper{
	width: 92%;
    margin: 20px auto 0;
    align-items: center;
    justify-content: space-between;
	padding: 0px 18px 0 0;
}

h3.deactivatte-msg{
	/* background-color: #0e72b324;
    color: #ff1313; */
	background: #e97451;
    border-color: #E44006;
    color: #fff;
    /* border-radius: 5px; */
    text-align: center;
    padding: 5px 10px 5px 10px;
	
}

.message-fixed{
	position: fixed;
	width: calc(100% - 375px);
	top: 0;
	display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
	/* z-index: 9999; */
	z-index: 1;
}


.right-container.message-added {
	margin-top: 20px;
}
.clear-btn:hover {
	background: #0e72b3;
	color: #fff;
	cursor: pointer;
}
.new-bol-tabopen {
	margin: 15px 0 45px 25px;

}

.newbol-form-box .newbol-fillig-block {
	border: 2px solid #a1a6ac;
	width: 100%;
	display: flex;
	
}
.newbol-form-box .newbol-fillig-block:not(:last-child) {
	border-bottom: none;
}
.newbol-fillig-block .newbol-filling-item:nth-child(2) {
	border-right: none;
}
.newbol-filling-item {
	padding: 15px;
	max-width: 50%;
    width: 100%;
	border-right: 2px solid #a1a6ac;
	
}
.newshipper-add-wrap {
	position: absolute;
    top: -15px;
    right: 7px;
    background: #fff;
    padding: 0px 5px;
	/* padding-bottom: 15px; */
}
.newshipper-add-wrap label, .newbol-filling-row-merge .new-bol-input-wrap label, .new-bol-input-wrap label, .table-structure-block h5 {
	/* color:#808080; */
	font-weight: 500;
	font-size: 14px;
}
.newshipper-add-wrap label span, .newbol-filling-row-merge .new-bol-input-wrap span,  .new-bol-input-wrap label span, .FMC-filling-wrap p span {
	color: #ff6000;
}
.newshipper-add-wrap p span {
	font-size:22px;
	font-weight:500;
	color:#0e72b3;
	padding-right: 5px;
}
.newshipper-add-wrap p {
	font-size: 16px;
	font-weight: normal;
	color:#0e72b3!important;
	display: flex;
	align-items: center;
	cursor: pointer;
}
/* .new-bol-input-wrap  {
	margin-bottom: 22px;
} */
.new-bol-input-wrap select {
	width: 100%;
	padding: 15px 10px;
	background: url(../../../public/images/select-down-arrow.png) 98% center no-repeat;
	-webkit-appearance: none;
}
.new-bol-input-wrap textarea {
	width: 100%;
	height: 63px;
	/* padding: 20px; */
	/* margin-top: 22px; */
}
textarea.point-good-single-item {
    height: 140px;
}
.new-bol-input-wrap textarea::placeholder, .new-bol-input-wrap input::placeholder,  .table-data-enter input::placeholder{ 
    font-size: 16px!important;
    color: #848591!important;
    font-family: 'Euclid Circular A';
    font-weight: 300;
  }
  
  /* Internet Explorer 10-11 */
  .new-bol-input-wrap textarea:-ms-input-placeholder, .new-bol-input-wrap input:-ms-input-placeholder, .table-data-enter input:-ms-input-placeholder{ 
    font-size: 16px!important;
    color: #848591!important;
    font-family: 'Euclid Circular A';
    font-weight: 300;
  }
  
  /* Microsoft Edge */
  .new-bol-input-wrap textarea::-ms-input-placeholder, .new-bol-input-wrap input::-ms-input-placeholder,  .table-data-enter input::-ms-input-placeholder { 
	font-size: 16px!important;
    color: #848591!important;
    font-family: 'Euclid Circular A';
    font-weight: 300;
  }
  .newbol-filling-row-merge {
	display: flex;
	width: 100%;
	column-gap: 15px;
	/* padding: 30px 35px 20px; */
	padding: 15px;
  }
  .newbol-filling-row-merge:not(:last-child) {
	border-bottom: 2px solid #a1a6ac;
  }
  .newbol-filling-row-merge input, .new-bol-input-wrap input, .single-field .new-bol-input-wrap select, .newbol-filling-item select, .new-bol-input-wrap textarea{
    width: 100%;
	padding: 10px ;
	/* margin-top: 20px; */
	font-size: 14px;
	font-weight: 300;
	font-family: 'RedditMono' !important;
  }


 .new-bol-input-wrap select option, .newbol-filling-item select option {
    /* width: 100%;
	padding: 10px ; */
	/* margin-top: 20px; */
	font-size: 14px;
	font-weight: 300;
	font-family: 'Euclid Circular A' !important;
  }

  .newbol-filling-row-merge .new-bol-input-wrap {
	/* width: 100%; */
	margin-bottom: 0;
  }


  .double-column {
	padding:0;
  }
  .newbol-filling-row-merge.export-refernce {
	/* padding: 20px 25px 20px; */
	padding: 15px;
  }
  .newbol-filling-row-merge.export-refernce .new-bol-input-wrap {
	width: 100%;
  }
  .export-refernce input {
	/* margin-top: 12px; */
  }
  .flex {
	display:flex;
  }
  .d-block {
	display: block;
  }

  .forwarding-agent-num {
	height: 80px;
	margin-bottom: 15px;
  }
  .point-good-single-item {
	height: 157px;
  }
  .label-detail {
	font-size: 14px;
	font-weight: 500;
	color:#0d0c22!important;
  }
  .newbol-filling-item p {
	/* font-size: 15px; */
	font-size: 13px;
	color: #0d0c22;
	line-height: 20px;
  }

  .double-column-input {
	display: flex;
  }
  .double-column-input-right {
	/* padding: 20px 35px 20px 35px; */
	padding: 15px 15px;
	border-bottom:  2px solid #a1a6ac;
    flex-grow: 1;
  }
  .double-column-input-left {
	/* padding: 20px 25px 20px 45px; */
	padding: 15px;
    border-right:  2px solid #a1a6ac;
    border-bottom:  2px solid #a1a6ac;
	max-width: 50%;
    width: 100%;
    
  }
  .double-column-input:last-child .double-column-input-right {
	border-right:  none;
    border-bottom:  none;
	padding: 35px 35px 20px 35px;
  }
  .double-column-input:last-child .double-column-input-left {
	border-bottom:  none;
	padding: 35px 25px 20px 45px;
  }
  .single-field {
	/* padding: 40px 35px 50px 40px; */
	padding: 15px;
  }

  .single-field select{
	width: 100%;
  }
  .single-field .new-bol-input-wrap {
	width: 100%;
  }
  .double-column-input .new-bol-input-wrap {
    margin-bottom: 0;
  }
  .newbol-filling-item.description-text {
	width: 100%;
	/* padding: 35px 25px 8px 50px; */
	padding: 20px 20px 8px 15px;
	
  }
  .newbol-filling-item-inner-scroll {
	position: relative;
	width: 100%;
    height: 100%;
  }
  .FMC-filling-wrap {
	display: flex;
    align-items: center;
    column-gap: 20px;
	margin: 45px 0 55px;
  }
  
  .FMC-filling-wrap p {
	font-size: 17px;
    font-weight: normal;
	line-height: 32px;
	margin-top: 10px;
  }
  .FMC-filling-wrap .new-bol-input-wrap {
	flex-grow: 1;
	position:relative;
  }
  .submit-save-btn-wrap {
	display: flex;
    align-items: center;
	column-gap: 15px;
  }
  .submit-save-btn-wrap input[type=submit],input[type=button] {
	background: #0e72b3;
	border: 1px solid #0e72b3;
    color: #fff;
    padding: 9px 22px 8px;
    max-width: 185px;
    width: 100%;
    font-size: 22px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
	cursor: pointer;
  }

  .bol-approve-reject-btn{
	margin-top: 15px;
  }
  .save-exit-btn {
	background: #fff;
	color: #0e72b3;
    padding: 9px 22px 8px;
    max-width: 185px;
    width: 100%;
    border: 1px solid #0e72b3;
    font-size: 22px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
	cursor: pointer;
  }
  .save-exit-btn:hover {
	background: #0e72b3;
    color: #fff;
    cursor: pointer;
  }
  .new-bol-table-form .newbol-filling-item {
	border-right: none;
	max-width: 100%;
	/* padding: 35px 50px 35px 45px; */
	padding: 15px;
  }
  

  .table-structure-wrap {
	width: 100%;
	height: 100%;
	position: relative;
  }
  .table-structure-block table {
	border: 1px solid #000;
    border-spacing: 0;
	width: 100%;
    min-width: 900px;
  }
  .table-structure-head-item:not(:last-child) {
	border-right: 1px solid #000;

  }

  .table-structure-head-item{
	border-bottom: 1px solid #000;
  }

  .table-data-enter {
	border-right: 1px solid #000000;
    border-bottom: 1px solid#c7bfbf;
	border-top: unset
  }
  .table-data-enter:last-child {
	border-right: 0;
	
  }
  .table-structure-body:last-child .table-data-enter{
	border-bottom: 0;
	border-top: 0;
	
  }
  .table-structure-head-item {
	padding: 15px;
	
	
  }
  .first-table-item {
	flex-grow: 1;
	
  }
/* 
  .sibling-table-item {
	max-width: 18%;
    width: 100%;
  } */
  .dop-width{
	max-width: 45%;
	width:40%;
	
  }

 
  .new-bol-table-form .new-bol-input-wrap {
    margin-bottom: 0;
 }

 .table-structure-head-item {
	font-size: 14px;
	line-height: 20px;
	font-weight: 500;
	text-transform: uppercase;
 }
 
 /* .table-data-enter input{
	padding: 15px 10px;
	border: none;
	width: 100%;
 } */
 .table-data-enter textarea{
	padding: 5px 10px;
	border: none;
	width: 100%;
	font-size: 17px;
	/* font-family: 'Euclid Circular A'; */
	font-family: 'RedditMono';
	resize: none;
    overflow: hidden;
	min-height:210px;
	margin-bottom: 10px;
 }
.main-table-data td{
	padding-top	:0px;
	padding-left: 0px;
	position: relative;
 }
 .table-structure-block h5 {
	margin-bottom: 15px;
 }
 .table-checkbox-text .form-checkbox .checkmark {
    /* width: 40px;
    height: 40px; */
	width: 30px;
    height: 30px;
	border: 1px solid #000;
	border-radius: 0;
 }
 .table-checkbox-text .form-checkbox {
	padding-left: 70px;
	font-size: 14px;
    line-height: 18px;
    font-weight: 300;
 }
 .table-checkbox-text {
	padding: 20px 0 15px;
 }
 .table-checkbox-text .form-checkbox .checkmark::after {
    content: "";
    position: absolute;
	left: 10px;
    top: 2px;
    width: 8px;
    height: 20px;
 }
/* --------update design----------------------------------------- */
.border-text {
	padding: 5px;
    border: 1px solid #9c9c9c;
    margin: 15px 0 0;
    position: relative;
	flex-grow: 1;
}
.border-text label {
	position:absolute;
	top:-10px;
	left:20px;
	background-color:white;
}
.border-text input, .border-text select, .border-text textarea{
	border:none;
}
.ship-exporter-textarea.border-text textarea {
	height: 120px;
}
.border-text select {
	border: 0px;
    outline: 0px;
}
.consign-textarea textarea {
	height: 120px;
 }
 .border-text textarea.notify-address{
	height:120px
 }
.table-heading-new-row {
	display: flex;
	justify-content:space-between;
	/* margin-bottom: 15px; */
}
.table-heading-new-row button {
	font-size: 16px;
    font-weight: normal;
    color: #0e72b3;
    display: flex;
    align-items: center;
}


/* ------------scroll customize---------------------------------------------------------------- */
#table_scrollinner_1 .iScrollHorizontalScrollbar, #table_scrollinner_2 .iScrollHorizontalScrollbar,
 #table_scrollinner_3 .iScrollHorizontalScrollbar, #table_scrollinner_4 .iScrollHorizontalScrollbar{
	position: absolute;
	z-index: 9999;
	height: 16px;
	left: 2px;
	right: 2px;
	bottom: 2px;
	overflow: hidden;
	background: transparent;
}
/* .iScrollHorizontalScrollbar .iScrollIndicator {
	height: 100%;
	background: -moz-linear-gradient(left,  #cc3f6e 0%, #93004e 100%);
	background: -webkit-linear-gradient(left,  #cc3f6e 0%,#93004e 100%);
	background: -o-linear-gradient(left,  #cc3f6e 0%,#93004e 100%);
	background: -ms-linear-gradient(left,  #cc3f6e 0%,#93004e 100%);
	background: linear-gradient(to right,  #cc3f6e 0%,#93004e 100%);
} */

#table_scrollinner_1 .iScrollHorizontalScrollbar.iScrollBothScrollbars, #table_scrollinner_2 .iScrollHorizontalScrollbar.iScrollBothScrollbars,
 #table_scrollinner_3 .iScrollHorizontalScrollbar.iScrollBothScrollbars, #table_scrollinner_4 .iScrollHorizontalScrollbar.iScrollBothScrollbars {
	right: 18px;
}


#scroll_1 .iScrollVerticalScrollbar, #scroll_2 .iScrollVerticalScrollbar {
	position: absolute;
	z-index: 9999;
	width: 16px;
	bottom: 2px;
	top: 2px;
	/* right: 2px; */
	right: -8px;
	overflow: hidden;
	background: transparent;
}
.iScrollHorizontalScrollbar.iScrollLoneScrollbar .iScrollIndicator {
	background-color: #c8c6c6 !important;
}

#scroll_1 .iScrollVerticalScrollbar.iScrollBothScrollbars, #scroll_2 .iScrollVerticalScrollbar.iScrollBothScrollbars {
	bottom: 18px;
}

#scroll_1 .iScrollIndicator, #scroll_2 .iScrollIndicator, #table_scrollinner_1 .iScrollIndicator,
 #table_scrollinner_2 .iScrollIndicator, #table_scrollinner_3 .iScrollIndicator, #table_scrollinner_4 .iScrollIndicator {
	position: absolute;
	/* background: #cc3f6e; */
	border-width: 1px;
	border-style: solid;
	border-color: #eaf1f5;
	border-radius: 8px;
	background-color: #c8c6c6 !important;
}


#scroll_1 .iScrollVerticalScrollbar .iScrollIndicator, #scroll_2 .iScrollVerticalScrollbar .iScrollIndicator {
	width: 50%;
	background: rgb(0 0 0 / 27%);
}
.scrollwrapper, .tablescrollWrapper {
	position: absolute;
	z-index: 1;
	top: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	overflow: hidden;
}

#scroller_1, #scroller_2, #table_scrollinner_1, #table_scrollinner_2, #table_scrollinner_3, #table_scrollinner_4{
	position: absolute;
	z-index: 1;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	/* width: 2000px; */
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	-ms-transform: translateZ(0);
	-o-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-text-size-adjust: none;
	-moz-text-size-adjust: none;
	-ms-text-size-adjust: none;
	-o-text-size-adjust: none;
	text-size-adjust: none;
	
}
.scroll-inner {
	padding-right: 20px;
}
.table-scroll-inner {
	min-width: 100%;
}
/* ------------scroll customize---------------------------------------------------------------- */
/* -------------REMOVE------------------------------ */
/* ------------Forgetpassword Popup----------------------------------------------------------- */
.forgotpasswrd-popupbg-overlay {
	background: rgb(0,0,0,25%);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
	z-index: 1999;
}
.forgotpasswrd-popupbox {
    position: absolute;
    top: 0;
    /* width: 100%; */
    padding: 100px 0;
    z-index: 2000;
    margin: auto;
    left: 0;
    right: 0;
    display: none;
}
.forgotpasswrd-popupbox-wrapper {
	background: #fff;
    padding: 45px 45px 60px;
    max-width: 425px;
    border-radius: 12px;
    width: 100%;
    margin: 0 auto;
    position: relative;
}
.forgotpasswrd-popupbox-close {
	position: absolute;
    right: 22px;
    top: 22px
}
.forgotpasswrd-popupbox-content .popup-logo{
	text-align: center;
    margin: 20px 0 40px;
}
.forgotpasswrd-popupbox-content .popup-logo img {
	max-width: 200px;
    width: 100%;
}
.forgotpasswrd-popupbox-content .popup-logo h4 {
	font-size: 22px;
    padding-top: 20px;
}
.forgot-form input[type="email"] {
	padding: 15px 20px;
    height: 55px;
    max-width: 485px;
    width: 100%;
    background: #fff;
    border: none;
    border-radius: 10px;
    font-size: 18px;
    margin-bottom: 25px;
    color: #848591;
    border: 1px solid #c8ccd2;
	font-family: 'Euclid Circular A';
}
.forgot-form input[type=submit] {
	display: inline-flex;
    align-items: center;
    background: #0e72b3;
    font-family: 'Euclid Circular A';
    font-size: 23px;
    font-weight: 400;
    color: #fff;
    border: 0;
    border-radius: 8px;
    padding: 0 20px;
    width: 100%;
    max-width: 430px;
    height: 59px;
    margin-bottom: 20px;
    justify-content: center;
    cursor: pointer;
	transition: .3s all ease-in-out;
}
.back-to-login {
	text-align: center;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    font-size: 19px;
    color: #000;
	transition: .3s all ease-in-out;
}

/* .newbol-filling-row-merge.none-flex-column, .newbol-filling-item.forward-agent-box{
	margin-top: 25px;
} */
.mt-0 {
	margin-top: 0;
}
table td:first-child, table th:first-child{
	text-align: left!important;
  }
  .newbol-filling-item-fullwidth{
	max-width: 100%;
	border-right: none;
}

.newbol-filling-item-fullwidth .new-bol-input-wrap{
	max-width: 300px;
    width: 100%;
    margin: 15px auto 0;
}
.newbol-filling-item-fullwidth .new-bol-input-wrap select{
	padding:5px;
}

/* .table-outer .table-main-item td:nth-child(3){
	width:40%;
} */

.table-structure-head-item sibling-table-item .Particulardeclaretable th:nth-child0{
	width: 10%;
}
.table-structure-head-item sibling-table-item .Particulardeclaretable th:nth-child1{
	width: 6%;
}
.table-structure-head-item sibling-table-item .Particulardeclaretable th:nth-child2{
	width: 64%;
}
.table-structure-head-item sibling-table-item .Particulardeclaretable th:nth-child3{
	width: 10%;
}
.table-structure-head-item sibling-table-item .Particulardeclaretable th:nth-child4{
	width: 10%;
}


.table-data-enter first-table-item .Particulardeclaretable th:nth-child0{
	width: 10%;
}
.table-data-enter first-table-item .Particulardeclaretable th:nth-child1{
	width: 6%;
}
.table-data-enter first-table-item .Particulardeclaretable th:nth-child2{
	width: 64%;
}
.table-data-enter first-table-item .Particulardeclaretable th:nth-child3{
	width: 10%;
}
.table-data-enter first-table-item .Particulardeclaretable th:nth-child4{
	width: 10%;
}

.submit-hover {background: #0e72b3;
    border: 1px solid #0e72b3;
    color: #fff;
    padding:9px 22px 8px;
    max-width: 185px;
    width: 100%;
    font-size: 22px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    cursor: pointer;
	}
.popupbox-content h3 {
	padding-left:0;
	text-align:center;
}
.b-none{
	border:none!important;
}
.bold-italic{
	font-weight: bold;
	font-style: italic;
}
.remove-btn-td{
	position: relative;
}

.remove-row-btn{
	position: absolute;
	right: 5px;
	bottom: 5px;
	width: 30px;
    height: 30px;
	border: solid 1px #0e72b3;
	border-radius: 10px;
	cursor: pointer;
	opacity: 0;
	transition: all ease .3s;
}

.table-structure-body:hover .remove-row-btn{
	opacity: 1;
}


.remove-row-btn::before{
	position: absolute;
	content: '';
	background: url(../../../public/images/delete-icon.png) no-repeat 0 0;
	background-size: 100%;
	width: 15px;
	height: 15px;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	filter: brightness(0) saturate(100%) invert(22%) sepia(75%) saturate(5044%) hue-rotate(339deg) brightness(85%) contrast(98%);
}

.react-datepicker-wrapper input::placeholder{
	color: #000!important;
	font-size: 14px;
    font-weight: 300;
    font-family: 'Euclid Circular A';
}

.bol-number {
	gap: 5px;
	align-items: center;
}

.badge-icon img{
	width:13px;
	display: block;
}

.badge-icon {
	flex-shrink: 0;
}

.remaining-characters {
    
	   border: solid 1px #0e72b3;
	   border-radius: 10px;
	   cursor: pointer;
	   opacity: 1;
	   transition: all ease .3s;
	   display: flex;
	   justify-content: center;
	   flex-direction: column;
	   padding: 4px 10px;
	   align-items: center;
	   margin-left: 5px;
	   width: max-content;
	   position: absolute;
       bottom: 5px;
}

.approve-td-flex,.badge-description p{
	gap: 5px;
	align-items: center;
}

.badge-description p{
	display: flex;
}

.badge-description{
	margin-top: 10px;
	display: flex;
	gap: 15px;
}

.badge-description .badge-icon img {
    width: 16px;
}


.bol-select-box{
	padding: 7px 10px;
  	position: relative;
	font-size: 14px;
  	font-weight: 300;
  	font-family: 'Euclid Circular A';
  	min-height: 30px;
	background: url(../../../public/images/select-down-arrow.png) 98% center no-repeat;
}

.bol-select-box .bol-select-option-list{
	position: absolute;
    width: calc(100%);
    background: #fff;
    border: solid 1px #9c9c9c;
    left: 0;
    top: 30px;
    z-index: 1;
    max-height: 120px;
    overflow: auto;
	display : none;
}

.bol-select-box .bol-select-option-list.open{
	display: block;
}

.bol-select-box .bol-select-option-list .bol-select-option-item{
	padding: 5px;
    cursor: context-menu;
	min-height: 25px;
}



.bol-select-box .bol-select-option-list .bol-select-option-item:hover{
	background-color: #e0e0e6;
}	



 /* -----------Media Query--------------------------------------------------------------- */

 /* -------------------------User--Newbol----start--------------------------------------------------------------------- */

/* @media screen and (max-width: 2000px) {
	.table-data-enter textarea {
		font-size: 1.2vw;
	}
} */

 @media screen and (max-width: 1845px) {
	.message-fixed {
		width: calc(100% - 20.325vw);
	}
 }

 @media screen and (max-width: 1700px) {
	/* .scroller_id {
		padding-right: 25px;
	} */
	.scroll-inner {
		padding-right: 20px;
	}
 }
 @media screen and (max-width: 1650px) {
	
	.newshipper-add-wrap label, .newbol-filling-row-merge .new-bol-input-wrap label, .new-bol-input-wrap label {
		color: #0d0c22;
		font-weight: 500;
		font-size: 15px;
	}

	.newbol-filling-item.description-text {
		padding: 24px 25px 8px 25px;
	}
	.newbol-filling-item.description-text {
		/* padding: 24px 25px 8px 25px; */
		padding: 20px 8px 8px 15px;
		/* position: relative; */
	}
	



	/* .newbol-filling-item.double-column .newbol-filling-row-merge .new-bol-input-wrap {
		margin-bottom: 22px;
	} */
	.newbol-filling-row-merge .new-bol-input-wrap.pre-carriage-width {
		max-width: 200px;
		width: 100%;
	  }
	.newbol-filling-row-merge .new-bol-input-wrap.port-loading-width {
	max-width: 200px;
	width: 100%;
	}
	.newbol-filling-row-merge .new-bol-input-wrap.port-discharge-width {
	max-width: 200px;
	width: 100%;
	}
	/* .table-data-enter textarea {
		font-size: 1.1vw;
	} */

 }
 @media screen and (max-width: 1550px) {
	.new-bol-header .version-bol-num {
		column-gap: 35px;
	}
	.table-data-enter textarea {
		font-size: 16px;
	}
 }
 @media screen and (max-width: 1450px) {
	/* .newbol-filling-item.double-column .newbol-filling-row-merge.none-flex-column{
		flex-direction: row;
	} */
	.newbol-filling-row-merge {
		flex-direction: column;
	}
	.newbol-filling-row-merge .new-bol-input-wrap.pre-carriage-width, .newbol-filling-row-merge .new-bol-input-wrap.port-discharge-width{
		max-width:100%;
		/* margin-bottom: 16px; */
	}
	.newbol-filling-row-merge .new-bol-input-wrap.port-loading-width  {
		margin-top:16px;
		max-width: 100%;
	}
	
	.single-item-center {
		display: flex;
		/* align-items: center; */
	}
	.single-item-center .new-bol-input-wrap {
		width: 100%;
	}
	.leftmenu-top .logo {
		width: 148px;
	}
	.ship-exporter-textarea textarea {
		height: 112px;
	 }
	
	 .ship-exporter-textarea {
		height: 180px;
	 }
	 .new-bol-header .version-bol-num {
		column-gap: 20px;
	}
	.version-bol-num h5 {
		font-size: 14px;
	}
	.new-bol-header .version-bol-num {
		column-gap: 20px;
	}
	.newbol-msg-btn {
		font-size: 17px;
	}

	.message-fixed {
		width: calc(100% - 258px);
	}
	.table-data-enter textarea {
		font-size: 15px;
	}
 }

 /* @media screen and (max-width: 1400px) {
	
} */

 @media screen and (max-width: 1279px) {
	.label-detail {
		font-size: 13px;
	}
 }

 @media screen and (max-width: 1300px) {
	.FMC-filling-wrap {
		margin: 25px 0 55px;
	}
	.newbol-msg-btn {
		font-size: 15px;
	}

	.message-fixed {
		width: 100%;
		top: 0px;
	}
	.table-data-enter textarea {
		font-size: 17px;
	}
	
 }
 @media screen and (max-width: 1200px) {
	.FMC-filling-wrap p {
		    max-width: 506px;
	}
	.table-data-enter textarea {
		font-size: 16px;
	}
 }
 @media screen and (max-width: 1075px) {
	.new-bol-header .version-bol-num{
		column-gap: 20px;
		
		max-width: 155px;
	}
	.newbol-msg-btn {
		font-size: 14px;
		max-width: 150px;
	}
	.new-bol-header .version-bol-num {
		column-gap: 10px;
	}
	.table-data-enter textarea {
		font-size: 14px;
	}

 }

 @media screen and (max-width: 1032px) {
	.new-bol-header.active .newbol-msg-notifywrap {
		max-width: 307px;
		column-gap: 17px;
	}
	.newbol-msg-notifywrap {
		/* max-width: 225px;
		column-gap: 17px; */
		/* max-width: 150px; */
		column-gap: 10px;
	}
	.new-bol-version-num {
		font-size: 17px;
		width: 28px;
		height: 28px;
		background-size: 22px;
		font-size: 16px;
	}
	
	.newbol-msg-btn {
		/* max-width: 155px; */
		    /* max-width: 225px; */
	}
	.newbol-form-add .newbol-msg-notifywrap {
		max-width: 310px;
	}
	.newbol-msg-notifywrap.notify-btn-wrapper {
		max-width: unset;
	}

	.save-exit-btn {
		padding: 15px 15px 15px;
		max-width: 165px;
		font-size: 20px;
	}
	.submit-save-btn-wrap input[type=submit] {
		padding: 15px 15px 15px;
		max-width: 156px;
		width: 100%;
		font-size: 20px;

	}
	/* .shipment-type-wrap .newbol-msg-notifywrap {
		position:absolute;
		left:50%;
		transform:translate-x(-50%);

	} */
}
@media screen and (max-width: 1000px) { 
	.newbol-filling-item.double-column .newbol-filling-row-merge.none-flex-column{
		flex-direction: column;
	}
	.table-structure-head-item {
		padding: 20px 15px 20px 15px;
	}
}
@media screen and (max-width: 990px) { 

	.version-bol-num h5 {
		font-size: 13px;
		font-weight: normal;
		display: inline-flex;
		flex-direction: column;
	}
	.newbol-msg-btn {
		padding: 12px 6px 12px 34px;
		border-radius: 8px
	}
	.new-bol-header .version-bol-num {
		column-gap: 15px;
	}

}
@media screen and (max-width: 900px) { 
	.version-bol-num h5 {
		/* font-size: 16px; */
		font-weight: normal;
		display: inline-flex;
		flex-direction: column;
	}
	.FMC-filling-wrap {
		flex-direction: column;
		justify-content: center;
		align-items: baseline;
	}
	.new-bol-header .version-bol-num {
		column-gap: 17px;
	}

	/* .table-data-enter textarea {
		font-size: 1.7vw;
	} */
	
}
.newbol-msg-notifywrap button img {
	width: 24px;
	height: 24px;
	object-fit: cover;
}

.information-tooltip{
	/* width: 25px; */
	display: flex;
}
  .addrow{
	position: relative;
  }
  .addrow::before{
	content: '';
    position: absolute;
    top: 6px;
    left: -225px;
    width: 218px;
  }
  .addrow:hover.addrow::before{
	content: 'Adds a rider page to this HBL';
	font-size: 13px;
	background-color: #0e72b324;
	color: #0041a5;
	border-radius: 5px;
    text-align: center;
  }
  .remaining-characters .tooltip{
	display: none;
  }

  .remaining-characters:hover .tooltip{
	font-size: 13px;
	background-color: #0e72b324;
	color: #0041a5;
	border-radius: 5px;
	position: absolute;
	left: calc(100% + 10px);
    top: 0;
	display: block;
	width: 150px;
	padding: 5px;
	z-index: 3;
  }

  .main-table-data td:last-child .remaining-characters:hover .tooltip{
	left: calc(100% - 210px);
  }

  
  